
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function CovidUpdateModal({ showCovidUpdate, setShowCovidUpdate}) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showCovidUpdate}
                onHide={() => {
                    setShowCovidUpdate(false);
                }}
            >
                <Modal.Header closeButton>
                    Covid 19 Update
                </Modal.Header>
                <Modal.Body>
                    <h6>During the COVID-19 pandemic, the Maldives effectively managed health and safety, becoming one of the first countries to reopen its borders and quickly reviving its tourism industry. The unique island setup allowed for secure, private accommodations, with many resorts operating safely even during border closures. Now fully recovered, the Maldives has achieved high occupancy rates, welcoming nearly 1.5 million tourists, with expectations to reach 2 million by 2025. This proactive response ensures the Maldives remains a top choice for travelers, combining safety with unmatched luxury and natural beauty. . </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowCovidUpdate(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CovidUpdateModal
