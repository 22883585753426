
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function TravelGuidModal({ showTravelGuid, setShowTravelGuid }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showTravelGuid}
                onHide={() => {
                    setShowTravelGuid(false);
                }}
            >
                <Modal.Header closeButton>
                    Covid 19 Update
                </Modal.Header>
                <Modal.Body>
                    <h6>Embarking on a journey to the Maldives is an exciting adventure, starting with your arrival at Velana International Airport. This major hub is serviced by several international and luxury airlines, connecting you directly to paradise from various parts of the world. Effortlessly book your tickets online and let us guide you for a hassle-free experience as you travel with your loved ones.
                    </h6>
                    <h6>Upon arrival, our dedicated staff will warmly meet and greet you, ensuring a smooth transition to your resort. Depending on your destination, you'll be whisked away by speedboat, seaplane, or domestic flight. With airports in every atoll, guests typically reach their hotels within an hour, maximizing your relaxation time. The exhilarating transfers are a part</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowTravelGuid(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TravelGuidModal
