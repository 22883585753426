import React,{useEffect, } from 'react'
import SideBar from 'Agent/components/SideBar'
import MainDashboard from 'Agent/components/MainDashboard'
import { getUserDetailsApi } from 'Customer/Apis/AuthApis/authApis'
import { setUserDetails } from '../../Redux/actions'
import { connect } from 'react-redux'

function Dashboard({setUserDetailsRedux}) {

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = async ()=>{
    let user_local_token = localStorage.getItem("maldive_auth_token")
    if(user_local_token){
      getUserDetailsApi(user_local_token, (data)=>{
        setUserDetailsRedux({user_data:data, user_token:user_local_token})
      })
    }
   
  }

  return (
    <div style={{background:"#f0f0f0"}} >
      <div className="container-fluid">
         <div className="row">
             <div className="col-2">
                 <SideBar />
             </div>
             <div className="col-10">
                 <MainDashboard />
             </div>
         </div>
      </div>
    </div>
  )
}



const mapStateToProps = (state) => {
  return {
    getUserDetailsRedux: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetailsRedux: (parameter) => {
      dispatch(setUserDetails(parameter));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

