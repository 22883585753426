import { useRef } from 'react'
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import 'Customer/Styles/MainStyles/holidaypackages.css'
import { image_url } from 'Utils/BaseURL';
import { useNavigate, Link } from 'react-router-dom';
import 'Customer/Styles/MainStyles/clientTestimonial.css'


function ClientTestimonials({ allHotelsList }) {
    const navigation = useNavigate()
    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const nextArrow = () => {
        sliderRef.slickNext();
    };
    const previousArrow = () => {
        sliderRef.slickPrev();
    };
    return (
        <>
            <section className="holiday_packages_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 position-relative">
                            <div className="text-center">
                                <h2>Client Testimonials</h2>
                            </div>

                            <div className="slider-container ">
                                <Slider
                                    ref={slider => {
                                        sliderRef = slider;
                                    }}
                                    {...settings}>
                                    {
                                        allHotelsList?.map((item, i) => {
                                            return <section key={i} className="testimonial_wrapper container">
                                                <div className="testimonial_wrapper_bg_main">
                                                    <button className='testimonial_arrow_buttons' ><IoIosArrowBack onClick={() => previousArrow()} /></button>
                                                    <div className="testimonial_wrapper_box">
                                                        <h2 className='text-white' >Client Testimonial</h2>
                                                        <h6 className='text-center text-white py-3' >“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                                            type and scrambled it to make a type specimen book.”</h6>
                                                        <div className="client_testimonial_users_details">
                                                            <img style={{width:"100px", height:"100px"}} src="/img/dummy.png" alt="img" />
                                                            <div className='mx-3' >
                                                                <h5 className='text-white' >Rahul</h5>
                                                                <h6 className='text-white'>CEO Cyberx</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className='testimonial_arrow_buttons' ><IoIosArrowForward onClick={() => nextArrow()} /></button>
                                                </div>
                                            </section>
                                        })
                                    }

                                </Slider>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClientTestimonials
