
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function ReturnPolicyModal({ showReturnPolicyModal, setShowReturnPolicyModal }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showReturnPolicyModal}
                onHide={() => {
                    setShowReturnPolicyModal(false);
                }}
            >
                <Modal.Header closeButton>
                    Return & Refund Policy
                </Modal.Header>
                <Modal.Body>
                    <h6> At Nalafalhu Holidays, we are committed to ensuring clarity and fairness in our return and refund policies, aligning with the terms set by the resorts, hotels, and guesthouses we partner with. Each service booked is backed by these specific policies, which are communicated in detail prior to confirmation. </h6>
                    <h6>Every accommodation provider has its own cancellation terms, which typically include a deadline by which guests can cancel their booking without penalty. Some providers may charge a certain percentage for cancellations, while others offer a cut-off period with no charges. </h6>
                    <h6>In the event of a refund, any applicable amount will be promptly remitted to the guest's account as per the specific policies of the accommodation provider. It is our priority to ensure that all return or refund processes are handled efficiently and transparently, giving you peace of mind throughout your booking journey with us. </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowReturnPolicyModal(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReturnPolicyModal
