import React from 'react'
import 'Customer/Styles/MainStyles/blogsandarticles.css'
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { image_url } from 'Utils/BaseURL';


function BlogsAndArticles({allBlogsList}) {
    let data = [1, 2, 3]
    console.log(allBlogsList,'allBlogsList')
    return (
        <>
            <section className="blogs_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="sec_heading">
                                <h2>Blogs & Articles</h2>
                            </div>
                        </div>

                        {
                            allBlogsList?.map((item, i) => {
                                return <div key={i} className="col-md-4 col-sm-6 col-12 mt-3">
                                    <div className="card">
                                        <img src={`${image_url}${item?.blog_image}`} className="card-img-top" alt="" />
                                        <div className="card-body">
                                            <div className="card-date d-flex align-items-center justify-content-between gap-4">
                                                <span><FaCalendarAlt className='mx-2' />January 20 2023 </span>
                                                <span><FaRegUser className='mx-2' />Admin</span>
                                            </div>
                                            <h5 className="card-title">{item?.blog_title}</h5>
                                            <div className="post__content" dangerouslySetInnerHTML={{__html: item?.description.slice(0,200)}}></div>
                                            <a href={`blog-details/${item?.slug}/${item?._id}`} className="btn">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogsAndArticles
