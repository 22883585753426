import { useState, useEffect, useLayoutEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import 'Customer/Styles/MainStyles/resortlistingmain.css'
import 'Customer/Styles/MainStyles/rooms/allroomslisting.css'
import { FaPlus, FaMinus } from "react-icons/fa";
import { image_url } from 'Utils/BaseURL';
import Table from 'react-bootstrap/Table';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorToaster } from 'Utils/Toast';
import BookingSuccessModal from 'Customer/Modals/BookingSuccessModal';
import { hotelBookingApi, checkRoomAvailablityApi } from 'Customer/Apis/MainApis/mainApis';
import { connect } from 'react-redux';

function AllRoomsList({ allRoomsLists,vendorId, hotelID, bookingSuccessShow, setBookingSuccessShow, getUserDetailsRedux }) {
    const navigation = useNavigate()
    var tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const [isCheckRoomIsAvailableId, setIsCheckRoomIsAvailableId] = useState(false)
    const [isCheckRoomIsAvailableIdAll, setIsCheckRoomIsAvailableIdAll] = useState(false)
    const [featureImage, setFeatureImage] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(tomorrowDate);


    const [isLogin, setIsLogin] = useState(false)
    const [bookingType, setBookingType] = useState('Transfer')
    const [roomType, setRoomType] = useState('')

    const [selectedRoomId, setSelectedRoomId] = useState()

    const [selectPerson, setSelectPerson] = useState(1)
    const [selectChild, setSelectChild] = useState(0)

    const [selectedAdultPrice, setSelectedAdultPrice] = useState(0)
    const [selectedChildPrice, setSelectedChildPrice] = useState(0)

    const [whichSelectedPrice, setWhichSelectedPrice] = useState(false)

    const [domesticFlightPrice, setDomesticFlightPrice] = useState(0)
    const [seaPlanPrice, setSeaPlanPrice] = useState(0)
    const [speedBoatPrice, setSpeedBoatPrice] = useState(0)

    const [selectedPriceTitle, setSelectedPriceTitle] = useState(false)
    const [showExtraPriceOption, setShowExtraPriceOption] = useState(false)

    useLayoutEffect(() => {
        let user_local_token = localStorage.getItem("maldive_auth_token")
        setIsLogin(user_local_token)
    }, [])

    useEffect(()=>{
        console.log('called', allRoomsLists)
        for (let i = 0; i < allRoomsLists?.length; i++) {
            let body = {
                "roomId": allRoomsLists[i]?._id,
                "startingDate": startDate,
                "endingDate": endDate
            }
            checkRoomAvailablityApi(body, (data) => {
                if (data) {
                    setIsCheckRoomIsAvailableIdAll(false)
                } else {
                    setIsCheckRoomIsAvailableIdAll(allRoomsLists[i]?._id)
                }
            })
        }
    },[allRoomsLists])

    const handleHotelBooking = async (roomId, baseChildPrice, baseAdultPrice) => {

        let user_local_token = localStorage.getItem("maldive_auth_token")
        let body = {
            "userId": getUserDetailsRedux?.user_data?._id,
            "hotelId": hotelID,
            "roomId": roomId,
            "totalAdult": selectPerson,
            "totalChild": selectChild,
            "adultPrice": selectedAdultPrice === 0 ? baseAdultPrice : selectedAdultPrice,
            "childPrice": selectedChildPrice === 0 ? baseChildPrice : selectedChildPrice,
            "couponNumber": "couponNumber",
            "checkInDate": startDate,
            "checkOutDate": endDate,
            "domesticFlightPrice": domesticFlightPrice,
            "seaPlanPrice": seaPlanPrice,
            "speedBoatPrice": speedBoatPrice,
            "bookingType": bookingType,
            "roomType": roomType,
            "vendorId":vendorId
        }
        hotelBookingApi(body, user_local_token, (data) => {
            if (data) {
                setBookingSuccessShow(true)
            }
        })
    }


    const ExampleStartingCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            className="w-100"
            onClick={onClick}
            type="text"
            id="maldive_room_date_selector"
            placeholder="Select a date"
            value={startDate}
            onChange={() => {

            }}
        />
    ));

    const ExampleEndingCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            className="w-100"
            onClick={onClick}
            type="text"
            id="maldive_room_date_selector"
            placeholder="Select a date"
            value={endDate}
            onChange={() => {

            }}
        />
    ));



    return (
        <div>
            <BookingSuccessModal bookingSuccessShow={bookingSuccessShow} setBookingSuccessShow={setBookingSuccessShow} />
            <div className="col-12">
                <div className="inner_content_list">
                    <div className='maldives_room_check_availability_box d-none' >
                        <div className="col-6">
                            <h4>Check Availability</h4>
                            <div className="row">

                                <div className="col-3">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        Check-In Date
                                    </label>

                                    <div>
                                        <DatePicker
                                            minDate={new Date()}
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            customInput={<ExampleStartingCustomInput />}
                                        />
                                    </div>
                                    <div className="calendar" id="calendar">
                                        <div className="header">
                                            <button id="prevBtn">&lt;</button>
                                            <h2 id="monthYear">Month Year</h2>
                                            <button id="nextBtn">&gt;</button>
                                        </div>
                                        <div className="days" id="daysContainer"></div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        Check-Out Date
                                    </label>
                                    <div>
                                        <DatePicker
                                            minDate={new Date()}
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            customInput={<ExampleEndingCustomInput />}
                                        />
                                    </div>
                                    <div className="calendar" id="calendar">
                                        <div className="header">
                                            <button id="prevBtn">&lt;</button>
                                            <h2 id="monthYear">Month Year</h2>
                                            <button id="nextBtn">&gt;</button>
                                        </div>
                                        <div className="days" id="daysContainer"></div>
                                    </div>
                                </div>

                                <div className='col-3 d-flex align-items-end justify-content-start ' >
                                    <button>Check</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        allRoomsLists?.map((item, index) => {
                            return <div key={index} className="row">
                                <div className="col-12">

                                    <div className="maldives_main_room_listing_card_box">
                                        <div className="row">
                                            <div className="col-xxl-4 col-xl-3 col-lg-3 d-flex align-items-center justify-content-center">
                                                <div className="list_img_gallery">
                                                    <div className="card-wrapper">
                                                        <div className="">
                                                            <div className="product-imgs">
                                                                <div className="img-display">
                                                                    <div className="img-showcase">
                                                                        {
                                                                            featureImage?.id === item?._id
                                                                                ?
                                                                                <img src={`${image_url}${featureImage?.src}`} alt="img" />
                                                                                :
                                                                                <img src={`${image_url}${item?.roomImage[0]?.src}`} alt="img" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="img-select">
                                                                    {
                                                                        item?.roomImage?.map((img, i) => {
                                                                            return <div key={i} className="img-item" onClick={() => {
                                                                                setFeatureImage({ id: item?._id, src: img?.src })
                                                                            }} >
                                                                                {
                                                                                    img?.src?.length === 0
                                                                                        ? null
                                                                                        :
                                                                                        <img src={`${image_url}${img?.src}`} alt="img" className='img-fluid' />
                                                                                }
                                                                                
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xxl-5 col-xl-6 col-lg-6">
                                                <div className="list_details_wrapper">
                                                    <div className="row">
                                                        <div className="col-12 d-flex justify-content-between">
                                                            <div className="rating_verify">
                                                                <div>
                                                                    <span>{item?.roomCategory}</span></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="sec_heading">
                                                                <h4>{item?.roomName}</h4>
                                                            </div>
                                                            <div className="list_content">
                                                                <p>
                                                                    {item?.roomTitle}
                                                                </p>
                                                                <div className="list_location d-flex align-items-center">
                                                                    <p>Number Of Bed :  {item?.bedNumber}</p>
                                                                </div>

                                                                <div className="list_amenities_wrapper">
                                                                    <h4>Amenities</h4>
                                                                    <div className="list_amenities d-flex">
                                                                        {
                                                                            item?.amenitites?.slice(0, 3)?.map((ele, i) => {
                                                                                return <p className='text-black border px-2 py-2 mx-1' key={i} >{ele?.name}</p>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="maldives_rooms_listing_middle_section_buttons">
                                                                    <div className='d-flex flex-column' >
                                                                        <label htmlFor="price1" className="form-label">Select Room Type</label>
                                                                        <select name="price1" id="price1"
                                                                            onChange={(e) => {
                                                                                let allData = item?.price
                                                                                let findIndexAutoReply = allData?.findIndex(
                                                                                    (val) => val?.title === e.target.value
                                                                                );
                                                                                let foundData = allData?.slice(findIndexAutoReply, findIndexAutoReply + 1);
                                                                                console.log(foundData, 'foundData')
                                                                                setSelectedPriceTitle(foundData[0])
                                                                                setRoomType(e.target.value)

                                                                                if (e.target.value === "false") {
                                                                                    setShowExtraPriceOption(false)
                                                                                } else {
                                                                                    setShowExtraPriceOption(true)
                                                                                }

                                                                            }} >
                                                                            <option value={false} onClick={() => {
                                                                                setSelectedRoomId('');
                                                                                setSelectedPriceTitle(false)
                                                                                setBookingType('transfer');
                                                                                setSelectedAdultPrice(0);
                                                                                setSelectedChildPrice(0)
                                                                            }} >No Select</option>
                                                                            {
                                                                                item?.price?.map((ele, i) => {
                                                                                    return <option key={i} value={ele?.title}>{ele?.title}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {/* <label htmlFor="price2" className="form-label ">Select Price</label> */}
                                                                    {
                                                                        showExtraPriceOption
                                                                            ?
                                                                            <div className='d-flex' >
                                                                                <div className='d-flex flex-column'>
                                                                                    <label htmlFor="price1" className="form-label">Select Price</label>
                                                                                    <select className='mx-2' name="price2" id="price2"
                                                                                        onChange={(e) => {
                                                                                            console.log(e.target.value, '-------2-')
                                                                                            if (e.target.value === 'Single Adult') {
                                                                                                setSelectedRoomId(item?._id)
                                                                                                setBookingType('Single Adult');
                                                                                                setSelectedAdultPrice(selectedPriceTitle?.singleAdultPrice);
                                                                                                setSelectedChildPrice(selectedPriceTitle?.childPrice)
                                                                                                setSelectPerson(1)
                                                                                            } else if (e.target.value === 'Double Adult') {
                                                                                                setSelectedRoomId(item?._id)
                                                                                                setBookingType('Double Adult');
                                                                                                setSelectedAdultPrice(selectedPriceTitle?.doubleAdultPrice);
                                                                                                setSelectedChildPrice(selectedPriceTitle?.childPrice)
                                                                                                setSelectPerson(1)
                                                                                            } else if (e.target.value === 'Triple Adult') {
                                                                                                setSelectedRoomId(item?._id)
                                                                                                setBookingType('Triple Adult');
                                                                                                setSelectedAdultPrice(selectedPriceTitle?.tripleAdultPrice);
                                                                                                setSelectedChildPrice(selectedPriceTitle?.childPrice)
                                                                                                setSelectPerson(1)
                                                                                            }
                                                                                        }} >
                                                                                        <option value='Single Adult'>Single Adult Price : {selectedPriceTitle?.singleAdultPrice}</option>
                                                                                        <option value='Double Adult'>Double Adult Price : {selectedPriceTitle?.doubleAdultPrice}</option>
                                                                                        <option value='Triple Adult'>Triple Adult Price : {selectedPriceTitle?.tripleAdultPrice}</option>

                                                                                    </select>
                                                                                </div>
                                                                                <div className='d-flex flex-column' >
                                                                                    <label htmlFor="price1" className="form-label">Child Price</label>
                                                                                    <select className='mx-2' name="price2" id="price2"
                                                                                        onChange={(e) => {
                                                                                            console.log(e.target.value, '--------')
                                                                                        }} >
                                                                                        <option onClick={() => setSelectedChildPrice(selectedPriceTitle?.childPrice)} value={selectedPriceTitle?.childPrice}>Child Price : {selectedPriceTitle?.childPrice}</option>

                                                                                    </select>
                                                                                </div>

                                                                            </div>
                                                                            :
                                                                            null
                                                                    }


                                                                    {/* <button className="cancellation my-2">Room Price Lists</button>
                                                                    <button className={`maldives_rooms_listing_remove_price_btn my-2 ${selectedAdultPrice === 0 || selectedRoomId !== item?._id ? 'd-none' : ''} `}
                                                                        onClick={() => {
                                                                            setWhichSelectedPrice(false)
                                                                            setSelectedAdultPrice(0)
                                                                            setSelectedChildPrice(0)
                                                                            setBookingType('Transfer')
                                                                            setRoomType('')
                                                                        }} >Remove</button> */}
                                                                    {/* <button className="add_on">Add On</button> */}
                                                                    <Table striped bordered hover className='d-none' >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th className='font-14' >Single Adult Price</th>
                                                                                <th className='font-14' >Double Adult Price</th>
                                                                                <th className='font-14' >Triple Adult Price</th>
                                                                                <th className='font-14' >Child Price</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='all_rooms_table_td' >

                                                                            {
                                                                                item?.price?.map((val, i) => {
                                                                                    return <tr key={i}>
                                                                                        <td className='font-14'>{val?.title} </td>
                                                                                        <td >
                                                                                            <div className='d-flex align-items-center'
                                                                                                onClick={() => {
                                                                                                    setSelectedRoomId(item?._id)
                                                                                                    // setWhichSelectedPrice(i) dont required
                                                                                                    setSelectedAdultPrice(val?.singleAdultPrice)
                                                                                                    setSelectedChildPrice(val?.childPrice)
                                                                                                    setBookingType('Single Adult')
                                                                                                    setRoomType(val?.title)
                                                                                                }} >
                                                                                                <span className='font-14'>${val?.singleAdultPrice}</span>
                                                                                                <input className="custom-radio" type="radio" id="adult_price" name="adult_price" value="adult_price" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'
                                                                                                onClick={() => {
                                                                                                    setSelectedRoomId(item?._id)
                                                                                                    // setWhichSelectedPrice(i)
                                                                                                    setSelectedAdultPrice(val?.doubleAdultPrice)
                                                                                                    setSelectedChildPrice(val?.childPrice)
                                                                                                    setBookingType('double Adult')
                                                                                                    setRoomType(val?.title)
                                                                                                }}
                                                                                            >
                                                                                                <span className='font-14'> ${val?.doubleAdultPrice}</span>
                                                                                                <input type="radio" id="adult_price" name="adult_price" value="adult_price" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'
                                                                                                onClick={() => {
                                                                                                    setSelectedRoomId(item?._id)
                                                                                                    // setWhichSelectedPrice(i)
                                                                                                    setSelectedAdultPrice(val?.tripleAdultPrice)
                                                                                                    setSelectedChildPrice(val?.childPrice)
                                                                                                    setBookingType('Triple Adult')
                                                                                                    setRoomType(val?.title)
                                                                                                }} >
                                                                                                <span className='font-14'>${val?.tripleAdultPrice}</span>
                                                                                                <input type="radio" id="adult_price" name="adult_price" value="adult_price" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'
                                                                                                onClick={() => {
                                                                                                    setSelectedRoomId(item?._id)
                                                                                                    setSelectedChildPrice(val?.childPrice)
                                                                                                }}
                                                                                            >
                                                                                                <span className='font-14'>${val?.childPrice}</span>
                                                                                                <input checked={true} disabled={whichSelectedPrice === i ? false : true} type="radio" id="child_price" name="child_price" value="child_price" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>

                                                                </div>

                                                                <div className='maldives_room_check_availability_box d-xxl-none d-block' >
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <h6>Check Availablity</h6>
                                                                            <div className="col-5">
                                                                                <label htmlFor="exampleCheckIn" className="form-label font-12">
                                                                                    Check-In
                                                                                </label>
                                                                                <div>
                                                                                    <DatePicker
                                                                                        minDate={new Date()}
                                                                                        selected={startDate}
                                                                                        onChange={(date) => setStartDate(date)}
                                                                                        customInput={<ExampleStartingCustomInput />}
                                                                                    />
                                                                                </div>
                                                                                <div className="calendar" id="calendar">
                                                                                    <div className="header">
                                                                                        <button id="prevBtn">&lt;</button>
                                                                                        <h2 id="monthYear">Month Year</h2>
                                                                                        <button id="nextBtn">&gt;</button>
                                                                                    </div>
                                                                                    <div className="days" id="daysContainer"></div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-5">
                                                                                <label htmlFor="exampleCheckIn" className="form-label font-12">
                                                                                    Check-Out
                                                                                </label>
                                                                                <div>
                                                                                    <DatePicker
                                                                                        minDate={tomorrowDate}
                                                                                        selected={endDate}
                                                                                        onChange={(date) => setEndDate(date)}
                                                                                        customInput={<ExampleEndingCustomInput />}
                                                                                    />
                                                                                </div>
                                                                                <div className="calendar" id="calendar">
                                                                                    <div className="header">
                                                                                        <button id="prevBtn">&lt;</button>
                                                                                        <h2 id="monthYear">Month Year</h2>
                                                                                        <button id="nextBtn">&gt;</button>
                                                                                    </div>
                                                                                    <div className="days" id="daysContainer"></div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-2 d-flex align-items-end justify-content-start '
                                                                                onClick={() => {
                                                                                    let body = {
                                                                                        "roomId": item?._id,
                                                                                        "startingDate": startDate,
                                                                                        "endingDate": endDate
                                                                                    }
                                                                                    console.log(body, '------------')
                                                                                    checkRoomAvailablityApi(body, (data) => {
                                                                                        if (data) {
                                                                                            setIsCheckRoomIsAvailableId(false)
                                                                                        } else {
                                                                                            setIsCheckRoomIsAvailableId(item?._id)
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <button>Check</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xxl-3 col-xl-3 col-lg-3 d-flex align-items-center justify-content-center">
                                                <div className="maldives_main_room_listing_card_box_list_last_section">
                                                    <div className='maldives_room_check_availability_box d-xxl-block d-none' >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <h6>Check Availablity</h6>
                                                                <div className="col-5">
                                                                    <label htmlFor="exampleCheckIn" className="form-label font-12">
                                                                        Check-In
                                                                    </label>
                                                                    <div>
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            selected={startDate}
                                                                            onChange={(date) => setStartDate(date)}
                                                                            customInput={<ExampleStartingCustomInput />}
                                                                        />
                                                                    </div>
                                                                    <div className="calendar" id="calendar">
                                                                        <div className="header">
                                                                            <button id="prevBtn">&lt;</button>
                                                                            <h2 id="monthYear">Month Year</h2>
                                                                            <button id="nextBtn">&gt;</button>
                                                                        </div>
                                                                        <div className="days" id="daysContainer"></div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-5">
                                                                    <label htmlFor="exampleCheckIn" className="form-label font-12">
                                                                        Check-Out
                                                                    </label>
                                                                    <div>
                                                                        <DatePicker
                                                                            minDate={tomorrowDate}
                                                                            selected={endDate}
                                                                            onChange={(date) => setEndDate(date)}
                                                                            customInput={<ExampleEndingCustomInput />}
                                                                        />
                                                                    </div>
                                                                    <div className="calendar" id="calendar">
                                                                        <div className="header">
                                                                            <button id="prevBtn">&lt;</button>
                                                                            <h2 id="monthYear">Month Year</h2>
                                                                            <button id="nextBtn">&gt;</button>
                                                                        </div>
                                                                        <div className="days" id="daysContainer"></div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-2 d-flex align-items-end justify-content-start '
                                                                    onClick={() => {
                                                                        let body = {
                                                                            "roomId": item?._id,
                                                                            "startingDate": startDate,
                                                                            "endingDate": endDate
                                                                        }
                                                                        console.log(body, '------------')
                                                                        checkRoomAvailablityApi(body, (data) => {
                                                                            if (data) {
                                                                                setIsCheckRoomIsAvailableId(false)
                                                                            } else {
                                                                                setIsCheckRoomIsAvailableId(item?._id)
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <button>Check</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="offer_wrapper">
                                                        <p>Free Cancellation </p>
                                                    </div>
                                                    {
                                                        selectedRoomId === item?._id
                                                            ?
                                                            <div className="maldives_main_room_listing_card_box_list_last_section_off_wrapper">
                                                                <span>{item?.discountInPercentage}% Off</span>
                                                                {
                                                                    selectedAdultPrice === 0
                                                                        ?
                                                                        <h6>Price : $ {item?.baseAdultPrice * selectPerson} </h6>
                                                                        :
                                                                        <h6>Price : $ {selectedAdultPrice} </h6>
                                                                }
                                                                {
                                                                    selectedChildPrice === 0 || selectedAdultPrice === 0
                                                                        ?
                                                                        <h6 className='d-flex align-items-center justify-content-end' >
                                                                            Child Price : $ {selectChild === 0 ? item?.baseChildPrice * 1 : item?.baseChildPrice * selectChild}
                                                                        </h6>
                                                                        :
                                                                        <h6 className='d-flex align-items-center justify-content-end'> Child Price :  {selectChild === 0 ? selectedChildPrice * 1 : selectedChildPrice * selectChild} $</h6>
                                                                }
                                                                <h6 className='d-flex align-items-center justify-content-end'>
                                                                    {speedBoatPrice === 0 ?
                                                                        <button onClick={() => setSpeedBoatPrice(item?.speedBoatPrice)} >Add </button>
                                                                        : <button className='maldive_room_remove_btn' onClick={() => setSpeedBoatPrice(0)} >Remove</button>
                                                                    }
                                                                    Speed Boat : $ {item?.speedBoatPrice}
                                                                </h6>
                                                                <h6 className='d-flex align-items-center justify-content-end'>
                                                                    {seaPlanPrice === 0 ?
                                                                        <button onClick={() => setSeaPlanPrice(item?.seaPlanPrice)} >Add </button>
                                                                        : <button className='maldive_room_remove_btn' onClick={() => setSeaPlanPrice(0)} >Remove</button>
                                                                    }
                                                                    Sea Plan : $ {item?.seaPlanPrice}
                                                                </h6>
                                                                <h6 className='d-flex align-items-center justify-content-end'>
                                                                    {domesticFlightPrice === 0 ?
                                                                        <button onClick={() => setDomesticFlightPrice(item?.domesticFlightPrice)} >Add </button>
                                                                        : <button className='maldive_room_remove_btn' onClick={() => setDomesticFlightPrice(0)} >Remove</button>
                                                                    }
                                                                    Domestic Flight : $ {item?.domesticFlightPrice}
                                                                </h6>
                                                                {
                                                                    selectedAdultPrice === 0
                                                                        ?
                                                                        selectChild > 0
                                                                            ?
                                                                            <h4>Total Price : $ {speedBoatPrice + seaPlanPrice + domesticFlightPrice + item?.baseChildPrice * selectChild + item?.baseAdultPrice * selectPerson}</h4>
                                                                            :
                                                                            <h4> Total Price : $ {speedBoatPrice + seaPlanPrice + domesticFlightPrice + item?.baseAdultPrice * selectPerson} </h4>
                                                                        :
                                                                        <h4> Total Price : $ {speedBoatPrice + seaPlanPrice + domesticFlightPrice + parseInt(selectedChildPrice) * selectChild + selectedAdultPrice * selectPerson} </h4>
                                                                }

                                                            </div>
                                                            :
                                                            <div className="maldives_main_room_listing_card_box_list_last_section_off_wrapper">
                                                                <span>{item?.discountInPercentage}% Off</span>
                                                                <h6>Price : $ {item?.baseAdultPrice} </h6>
                                                                <h6 className='d-flex align-items-center justify-content-end' >
                                                                    Child Price : $ {item?.baseChildPrice}
                                                                </h6>

                                                                <h6 className='d-flex align-items-center justify-content-end'>
                                                                    <button onClick={() => { setSpeedBoatPrice(item?.speedBoatPrice); setSelectedRoomId(item?._id) }} >Add</button>
                                                                    Speed Boat : $ {item?.speedBoatPrice}
                                                                </h6>
                                                                <h6 className='d-flex align-items-center justify-content-end'>
                                                                    <button onClick={() => { setSeaPlanPrice(item?.seaPlanPrice); setSelectedRoomId(item?._id) }} >Add</button>
                                                                    Sea Plan : $ {item?.seaPlanPrice}
                                                                </h6>
                                                                <h6 className='d-flex align-items-center justify-content-end'>
                                                                    <button onClick={() => { setDomesticFlightPrice(item?.domesticFlightPrice); setSelectedRoomId(item?._id) }} >Add</button>
                                                                    Domestic Flight : $ {item?.domesticFlightPrice}
                                                                </h6>

                                                                <h4>Total Price : $ {item?.baseAdultPrice}  </h4>


                                                            </div>
                                                    }
                                                    {
                                                        selectedRoomId === item?._id
                                                            ?
                                                            <div className='d-flex justify-content-end py-3' >
                                                                <div className={`maldives_main_resort_listing_card_box_list_last_section_child mx-2 ${selectedAdultPrice === 0 ? 'd-block' : "d-none"}`}>
                                                                    <label htmlFor="exampleCheckIn" className="form-label">Adults</label>
                                                                    <div className="maldive_room_inc_dec_box">
                                                                        <span className="minus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                                if (selectPerson <= 1) {

                                                                                } else {
                                                                                    setSelectPerson(selectPerson - 1)
                                                                                }
                                                                            }}
                                                                        ><FaMinus /></span>
                                                                        <span>{selectPerson}</span>
                                                                        <span className="plus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                                if (item?.maxAdultAllow === selectPerson) {
                                                                                    setSelectPerson(selectPerson)
                                                                                } else {
                                                                                    setSelectPerson(selectPerson + 1)
                                                                                }

                                                                            }}
                                                                        ><FaPlus /></span>
                                                                    </div>
                                                                </div>
                                                                <div className="maldives_main_resort_listing_card_box_list_last_section_child ">
                                                                    <label htmlFor="exampleCheckIn" className="form-label">Childs</label>
                                                                    <div className="maldive_room_inc_dec_box">
                                                                        <span className="minus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                                if (selectChild <= 0) {

                                                                                } else {
                                                                                    setSelectChild(selectChild - 1)
                                                                                }
                                                                            }}
                                                                        ><FaMinus /></span>
                                                                        <span>{selectChild}</span>
                                                                        <span className="plus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                                if (item?.maxChildAllow === selectChild) {
                                                                                    setSelectChild(selectChild)
                                                                                } else {
                                                                                    setSelectChild(selectChild + 1)
                                                                                }

                                                                            }}
                                                                        ><FaPlus /></span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            <div className='d-flex justify-content-end py-3' >
                                                                <div className={`maldives_main_resort_listing_card_box_list_last_section_child mx-2 ${selectedAdultPrice === 0 ? 'd-block' : "d-none"}`}>
                                                                    <label htmlFor="exampleCheckIn" className="form-label">Adults</label>
                                                                    <div className="maldive_room_inc_dec_box">
                                                                        <span className="minus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                            }}
                                                                        ><FaMinus /></span>
                                                                        <span>1</span>
                                                                        <span className="plus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                            }} ><FaPlus /></span>
                                                                    </div>
                                                                </div>
                                                                <div className="maldives_main_resort_listing_card_box_list_last_section_child ">
                                                                    <label htmlFor="exampleCheckIn" className="form-label">Childs</label>
                                                                    <div className="maldive_room_inc_dec_box">
                                                                        <span className="minus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                            }}><FaMinus /></span>
                                                                        <span>0</span>
                                                                        <span className="plus"
                                                                            onClick={() => {
                                                                                setSelectedRoomId(item?._id)
                                                                            }}><FaPlus /></span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                    }
                                                    <div className="list_last_btn d-flex flex-column">
                                                        {
                                                            isCheckRoomIsAvailableId === item?._id || isCheckRoomIsAvailableIdAll===item?._id
                                                                ?
                                                                <button className='maldive_room_not_available_btn' >Not Available</button>
                                                                :
                                                                <button className="book_now_button"
                                                                    onClick={() => {
                                                                        let user_local_token = localStorage.getItem("maldive_auth_token")
                                                                        if (user_local_token) {
                                                                            handleHotelBooking(item?._id, item?.baseChildPrice, item?.baseAdultPrice)
                                                                        } else {
                                                                            ErrorToaster('Please Login To Booking')
                                                                        }
                                                                    }}
                                                                >Book Now</button>

                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        getUserDetailsRedux: state.setUserDetailsReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllRoomsList);

