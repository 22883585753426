import * as React from "react";
import { createRoot } from "react-dom/client";
import LoginPage from "Customer/Pages/AuthPages/LoginPage";
import SignUpPage from "Customer/Pages/AuthPages/SignUpPage";
import ForgotPasswordPage from "Customer/Pages/AuthPages/ForgotPasswordPage";
import ResetPasswordPage from "Customer/Pages/AuthPages/ResetPasswordPage";

import HomePage from "Customer/Pages/MainPages/HomePage";
import AboutUs from "Customer/Pages/MainPages/AboutUs";
import ContactUs from "Customer/Pages/MainPages/ContactUs";

import PackageListing from "Customer/Pages/MainPages/Packages/PackageListing";
import PackagesDetailsPage from "Customer/Pages/MainPages/Packages/PackagesDetailsPage";
import PackagesDetailsOfferPage from "Customer/Pages/MainPages/Packages/PackagesDetailsOfferPage";

import ResortsListing from "Customer/Pages/MainPages/Restorts/ResortsListing";
import ResortsDetailsPage from "Customer/Pages/MainPages/Restorts/ResortsDetailsPage";

import JungleSafariListing from "Customer/Pages/MainPages/JungleSafaris/JungleSafariListing";
import JungleSafariDetailsPage from "Customer/Pages/MainPages/JungleSafaris/JungleSafariDetailsPage";
import JungleSafariDetailsOfferPage from "Customer/Pages/MainPages/JungleSafaris/JungleSafariDetailsOfferPage";

import MyBookingPage from "Customer/Pages/MainPages/MyBookingPage";
import AllOffersListing from "Customer/Pages/AllOffersListing";

import ResortOfferListing from "Customer/Pages/MainPages/Restorts/ResortOfferListing";
import PackageOfferListing from "Customer/Pages/MainPages/Packages/PackageOfferListing";
import JungleSafariOfferListing from "Customer/Pages/MainPages/JungleSafaris/JungleSafariOfferListing";

import SearchHomePage from "Customer/Pages/MainPages/SearchHomePage";

import SegmentDetailsPage from "Customer/Pages/SegmentDetailsPage";
import AllSegmentsListing from "Customer/Pages/AllSegmentsListing";

import MyOrderTable from "Customer/Pages/MainPages/MyOrderTable";

import BlogDetailsPage from "Customer/Pages/MainPages/BlogDetailsPage";

////////////// agents routes

import Dashboard from "Agent/pages/Dashboard";
import MyBookings from "Agent/pages/MyBookings";
import EditProfile from "Agent/pages/EditProfile";
import ChangePassword from "Agent/pages/ChangePassword";
import Transactions from "Agent/pages/Transactions";
import MyPackageBooking from "Agent/pages/MyPackageBooking";
import MyJungleSafariBooking from "Agent/pages/MyJungleSafariBooking";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import Testing from "Testing";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password/:slug/:slug",
    element: <ResetPasswordPage />,
  },
  {
    path: "/blog-details/:slug/:slug",
    element: <BlogDetailsPage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/customer/about-us",
    element: <AboutUs />,
  },
  {
    path: "/customer/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/customer/packages-listing",
    element: <PackageListing />,
  },
  {
    path: "/customer/package-details/:slug",
    element: <PackagesDetailsPage />,
  },
  {
    path: "/customer/package-details-offer/:slug/:slug/:slug",
    element: <PackagesDetailsOfferPage />,
  },
  {
    path: "/customer/hotels-listing",
    element: <ResortsListing />,
  },
  {
    path: "/customer/resort-details/:slug",
    element: <ResortsDetailsPage />,
  },
  {
    path: "/customer/jungle-safari-listing",
    element: <JungleSafariListing />,
  },
  {
    path: "/customer/jungle-safari-details/:slug",
    element: <JungleSafariDetailsPage />,
  },
  {
    path: "/customer/jungle-safari-details-offer/:slug/:slug/:slug",
    element: <JungleSafariDetailsOfferPage />,
  },
  {
    path: "/customer/my-bookings-details",
    element: <MyBookingPage />,
  },
  {
    path: "/view-all-offers",
    element: <AllOffersListing />,
  },
  {
    path: "/view-all-offers/hotels/:slug/:slug",
    element: <ResortOfferListing />,
  },
  {
    path: "/view-all-offers/packages/:slug/:slug",
    element: <PackageOfferListing />,
  },
  {
    path: "/view-all-offers/jungle-safari/:slug/:slug",
    element: <JungleSafariOfferListing />,
  },
  {
    path: "/search-hotel/:slug/:slug/:slug/:slug",
    element: <SearchHomePage />,
  },

  {
    path: "/all-segments-list",
    element: <AllSegmentsListing />,
  },
  {
    path: "/:slug/:slug",
    element: <SegmentDetailsPage />,
  },
  {
    path: "/my-orders",
    element: <MyOrderTable />,
  },

  //////////////agents routes
  {
    path: "/agent/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/agent/my-hotel-bookings",
    element: <MyBookings />,
  },
  {
    path: "/agent/my-jungle-safari-bookings",
    element: <MyJungleSafariBooking />,
  },
  {
    path: "/agent/my-package-bookings",
    element: <MyPackageBooking />,
  },
  {
    path: "/agent/edit-profile",
    element: <EditProfile />,
  },
  {
    path: "/agent/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/agent/all-tansactions",
    element: <Transactions />,
  },
  {
    path: "/customer/testing",
    element: <Testing />,
  },
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
