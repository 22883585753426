import React from 'react'
import './../styles/mybookings.css'
import TopBar from 'Agent/layouts/TopBar'
import { image_url } from 'Utils/BaseURL'
import moment from 'moment'

function AllBookings({ allHotelBookingData }) {
    return (
        <div>
            <div className="dashbord_content_wrapper">
                <div className="row">
                    <div className="col-12">
                        <TopBar />
                    </div>

                    <div className="col-12">
                        <div className="dashboard_content h-auto">
                            <div className="customer_booking_table">
                                <table className="table">
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid rgba(28, 27, 31, 0.5)" }}>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Hotel Name</th>
                                            <th scope="col">Total Price</th>
                                            <th scope="col">Booking Type </th>
                                            <th scope="col">Check In</th>
                                            <th scope="col">Check Out</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allHotelBookingData?.map((item, i) => {
                                                return <tr key={i} >
                                                    <td scope="row">
                                                        <div className="table_profile d-flex align-items-center">
                                                            <div className="table_profile_img">
                                                                <img src={`${image_url}${item?.user_details?.profileImg}`} alt="img" />
                                                            </div>

                                                            <div className="table_profile_name ms-2">
                                                                <p>Mr. {item?.user_details?.firstName}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='pt-3' >{item?.hotel_details?.hotelName}</td>
                                                    <td className='pt-3' >$ {item?.adultPrice * item?.totalAdult + item?.childPrice} </td>
                                                    <td className='pt-3' >{item?.bookingType}</td>
                                                    <td className='pt-3' >{moment(item?.checkInDate).format('ll')}</td>
                                                    <td className='pt-3' >{moment(item?.checkOutDate).format('ll')}</td>
                                                    <td className='pt-3' >{Math.round(Math.abs((new Date(item?.checkInDate) - new Date(item?.checkOutDate)) / 8.64e7))} Days</td>
                                                    <td> <button className="btn btn_confirm"><a href="#">Confirmed</a></button> </td>
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllBookings
