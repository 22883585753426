import React, { useState, useEffect } from 'react'
import ResortFilters from 'Customer/Layouts/ResortListingLayouts/ResortFilters'
import ResortListingLayout from 'Customer/Layouts/ResortListingLayouts/ResortListingLayout'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getAllHotelsApi, applyFiltersOnHotels } from 'Customer/Apis/MainApis/mainApis'

function ResortsListing() {
  const [allHotelsListing, setAllHotelsListing] = useState([])
  const [page, setPage] = useState(1)

  //////// filters states////
  const [selectedPropertyType, setSelectedPropertyType] = useState('')
  const [selectMinPrice, setSelectedMinPrice] = useState()
  const [selectMaxPrice, setSelectedMaxPrice] = useState()
  const [latitute, setLatitute] = useState(4.214294)
  const [longitute, setLongitute] = useState(73.541328)


  useEffect(() => {
    getAllHotels()
  }, [page])

  const getAllHotels = async () => {
    getAllHotelsApi(page, (data) => {
      setAllHotelsListing(data)
    })
  }

  useEffect(() => {
    applyFilters()
  }, [selectedPropertyType, selectMinPrice, selectMaxPrice])

  const applyFilters = async () => {
    let body = {
      "latitude": latitute,
      "longitude": longitute,
      "maxPrice": selectMaxPrice,
      "minPrice": selectMinPrice,
      "hotelType": selectedPropertyType
    }
    applyFiltersOnHotels(body, (data) => {
      setAllHotelsListing(data?.data)
    })
  }


  return (
    <div>
      <HomeBanner />

      <section className="mx-sm-0 px-sm-0 mx-xl-1 px-xl-1 mx-lg-1 px-lg-1 mx-xxl-5 px-xxl-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            < div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-xxl-2 col-xl-3 col-lg-3">
                    <ResortFilters
                      setSelectedPropertyType={setSelectedPropertyType}
                      setSelectedMinPrice={setSelectedMinPrice}
                      setSelectedMaxPrice={setSelectedMaxPrice}
                      setLatitute={setLatitute}
                      setLongitute={setLongitute}
                    />
                  </div>
                  <div className='col-xxl-10 col-xl-9 col-lg-9' >
                    <ResortListingLayout allHotelsListing={allHotelsListing} setPage={setPage} page={page} />

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResortsListing
