import {useEffect, useState} from 'react'
import SideBar from 'Agent/components/SideBar'
import AllBookings from 'Agent/components/AllBookings'
import { getMyJungleSafariBookingApi } from 'Agent/apis/mainApis'
import moment from 'moment'
import TopBar from 'Agent/layouts/TopBar'
import { image_url } from 'Utils/BaseURL'

function MyJungleSafariBooking() {

  const [page, setPage] = useState(1)
  const [allHotelBookingData, setAllHotelBookingData] = useState([])

  useEffect(()=>{
    getData()
  },[])

  const getData = async()=>{
    let user_local_token = localStorage.getItem("maldive_auth_token")
    getMyJungleSafariBookingApi(user_local_token, page, (data)=>{
      setAllHotelBookingData(data?.data)
    })
  }

  console.log(allHotelBookingData,'allHotelBookingData')


  return (
    <div style={{background:"#f0f0f0"}} >
      <div className="container-fluid">
         <div className="row">
             <div className="col-2">
                 <SideBar />
             </div>
             <div className="col-10">
             <div className="dashbord_content_wrapper">
                <div className="row">
                    <div className="col-12">
                        <TopBar />
                    </div>

                    <div className="col-12">
                        <div className="dashboard_content h-auto">
                            <div className="customer_booking_table">
                                <table className="table">
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid rgba(28, 27, 31, 0.5)" }}>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Total Price</th>
                                            <th scope="col">Booking Type </th>
                                            <th scope="col">Boarding Date</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allHotelBookingData?.map((item, i) => {
                                                return <tr key={i} >
                                                    <td scope="row">
                                                        <div className="table_profile d-flex align-items-center">
                                                            <div className="table_profile_img">
                                                                <img src={`${image_url}${item?.user_details?.profileImg}`} alt="img" />
                                                            </div>

                                                            <div className="table_profile_name ms-2">
                                                                <p>Mr. {item?.user_details?.firstName}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='pt-3' >{item?.jungle_safari_details?.jungleSafariName}</td>
                                                    <td className='pt-3' >$ {item?.total_price} </td>
                                                    <td className='pt-3' >{item?.jungle_safari_details?.totalMembers}</td>
                                                    <td className='pt-3' >{moment(item?.checkInDate).format('ll')}</td>
                                                    <td className='pt-3' >{Math.round(Math.abs((new Date(item?.checkInDate) - new Date(item?.checkOutDate)) / 8.64e7))} Days</td>
                                                    <td> <button className="btn btn_confirm"><a href="#">Confirmed</a></button> </td>
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             </div>
         </div>
      </div>
    </div>
  )
}

export default MyJungleSafariBooking
