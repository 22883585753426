
import { baseURL } from "Utils/BaseURL";
import axios from "axios";

export const getMyHotelBookingApi = async(token,page, callback)=>{
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseURL}/vendor/get-all-hotel-booking/${page}`,
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        callback(response?.data)
      })
      .catch((error) => {
        console.log(error);
      });
}

export const getMyPackageBookingApi = async(token,page, callback)=>{
  let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/vendor/get-all-package-booking/${page}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data)
    })
    .catch((error) => {
      console.log(error);
    });
}

export const getMyJungleSafariBookingApi = async(token,page, callback)=>{
  let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/vendor/get-all-jungle-safari-booking/${page}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data)
    })
    .catch((error) => {
      console.log(error);
    });
}