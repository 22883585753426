import React, { useState, useEffect, forwardRef } from "react";
import { image_url } from "Utils/BaseURL";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "Customer/Styles/MainStyles/packageDetails/packageaddonmodal.css";
import "Customer/Styles/MainStyles/profileModal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { uploadImageApi, updateUserProfile } from "Customer/Apis/MainApis/mainApis";
import { BsFillCameraFill } from "react-icons/bs";
import { connect } from "react-redux";

function ProfileModal({
    openUpdateProfileModal,
    setOpenUpdateProfileModal,
    getUserDetailsRedux
}) {
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [profile_img, setProfile_img] = useState('')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [DOB, setDOB] = useState('')
    const [gender, setGender] = useState('')
    const [phone, setPhone] = useState('')

    const DOBInput = forwardRef(({ value, onClick }, ref) => (
        <input
            className="w-100"
            onClick={onClick}
            type="text"
            id="dateInput"
            placeholder="Select a date"
            value={dateOfBirth}
            onChange={() => {

            }}
        />
    ));

    useEffect(()=>{
        setFirstName(getUserDetailsRedux?.user_data?.firstName)
        setLastName(getUserDetailsRedux?.user_data?.lastName)
        setEmail(getUserDetailsRedux?.user_data?.email)
        setDOB(getUserDetailsRedux?.user_data?.DOB)
        setGender(getUserDetailsRedux?.user_data?.gender)
        setProfile_img(getUserDetailsRedux?.user_data?.profileImg)
        setPhone(getUserDetailsRedux?.user_data?.phone)
    },[getUserDetailsRedux])

    const handleUpdate= async(e)=>{
        e.preventDefault()
        let user_local_token = localStorage.getItem("maldive_auth_token")
        let body={
            "profileImg": profile_img,
            "firstName": firstName,
            "lastName": lastName,
            "phone": phone,
            "gender": gender,
            "DOB": DOB
          }
          updateUserProfile(body, user_local_token)
    }

    return (
        <div>
            <Modal
                centered
                size="lg"
                show={openUpdateProfileModal}
                onHide={() => {
                    setOpenUpdateProfileModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Your Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="user_profile_image" >
                            <img src={profile_img === '' ? getUserDetailsRedux?.user_data?.profileImg : `${image_url}${profile_img}`} alt="profile_image" />
                            <label htmlFor="upload_img">
                                <BsFillCameraFill />
                                <input
                                    id="upload_img"
                                    type="file"
                                    placeholder="something"
                                    className="form-control"
                                    onChange={(event) =>
                                        uploadImageApi(event.target.files[0], (data) => {
                                            setProfile_img(data)
                                        })
                                    }
                                />
                            </label>
                        </div>
                        <form onSubmit={(e) => { handleUpdate(e)}}>
                            <div className="row update_profile_form">
                                <div className="col-6">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        First Name
                                    </label>
                                    <input
                                        className="form-control me-2"
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        Last Name
                                    </label>
                                    <input
                                        className="form-control me-2"
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value)
                                        }}
                                    />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        className="form-control me-2"
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        Gender
                                    </label>
                                    <input
                                        className="form-control me-2"
                                        type="text"
                                        placeholder="Gender"
                                        value={gender}
                                        onChange={(e) => {
                                            setGender(e.target.value)
                                        }}
                                    />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="exampleCheckIn" className="form-label">
                                        Date Of Birth
                                    </label>

                                    <div>
                                        <DatePicker
                                            maxDate={new Date()}
                                            selected={dateOfBirth}
                                            onChange={(date) => setDateOfBirth(date)}
                                            customInput={<DOBInput />}
                                        />
                                    </div>
                                
                                </div>







                                <div className="col-12 ">
                                    <button type="submit" className="choose_trip_form_btn">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        getUserDetailsRedux: state.setUserDetailsReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);

