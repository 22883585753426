import { useState, useLayoutEffect } from 'react'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import JungleSafariPhotoGallery from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariPhotoGallery'
import JungleSafariMainDetails from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariMainDetails'
import JungleSafariTabs from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariTabs'
import JungleSafariOverview from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariOverview'
import JungleSafariAmenities from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariAmenities'
import JungleSafariLocation from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariLocation'
import JungleSafariGuestReview from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariGuestReview'
import JungleSafariCancellationPolicy from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariCancellationPolicy'

import JungleSafariPolicy from 'Customer/Layouts/JungleSafariDetailsPageLayouts/JungleSafariPolicy'
import PackageSimiliar from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageSimiliar'
import { getSingleOfferApi, getAllOffersApi } from 'Customer/Apis/MainApis/mainApis'

function JungleSafariDetailsOfferPage() {
  const [selectedTab, setSelectedTab] = useState('1')
  const [singlePackageData, setSinglePackageData] = useState(false)
  const [allOfferLists, setAllOffersList] = useState([])

  const [singleHotelRatings, setSingleHotelRatings] = useState([])
  const [totalRatings, setTotalRatings] = useState()

  let packageID = window.location.pathname.split("/")[3];
  let offerId = window.location.pathname.split("/")[4];
  let offerDiscountPrice = window.location.pathname.split("/")[5];

  useLayoutEffect(() => {
    getSinglePackages()
    getAllOffers()
  }, [packageID])

  const getSinglePackages = async () => {
    getSingleOfferApi(offerId,offerDiscountPrice,packageID,(data) => {
      setSinglePackageData(data?.data[0])
      setSingleHotelRatings(data?.ratings)
      setTotalRatings(data?.avgRating)
    })
  }
  const getAllOffers = async () => {
    getAllOffersApi(1, "Jungle Safari", (data) => {
      setAllOffersList(data?.data)
    })
  }
  
  return (
    <div>
      <HomeBanner />
      <JungleSafariPhotoGallery singlePackageData={singlePackageData} allOfferLists={allOfferLists} />
      <JungleSafariMainDetails singlePackageData={singlePackageData} />
      <JungleSafariTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {
        selectedTab === '1'
          ?
          <JungleSafariOverview singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '2'
          ?
          <JungleSafariAmenities singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '3'
          ?
          <JungleSafariLocation singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '4'
          ?
          <JungleSafariGuestReview singlePackageData={singlePackageData} singleHotelRatings={singleHotelRatings} totalRatings={totalRatings} />
          : null
      }
      {
        selectedTab === '5'
          ?
          <JungleSafariCancellationPolicy singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '6'
          ?
          <JungleSafariPolicy singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '7'
          ?
          <PackageSimiliar />
          : null
      }






    </div>
  )
}

export default JungleSafariDetailsOfferPage
