import React from 'react'
import { connect } from 'react-redux';

function TopBar({getUserDetailsRedux}) {
    console.log(getUserDetailsRedux,'getUserDetailsRedux')
    return (
        <div>
            <div className="dashboard_main-upper_wrapper d-flex justify-content-between align-items-center">
                <h1>Dashboard</h1>
                <div className="profile_sec_wrapper d-flex align-items-center">
                    <div className="notification me-4">
                        <a href=""> <img src="/img/notification.png" alt="" /></a>
                    </div>

                    <div className="profile_wrapper d-flex align-items-center">
                        <div className="dashboard_profile_img">
                            <img src="/img/dummy.png" alt="img" />
                        </div>
                        <div className="profile_name">
                            Mr. {getUserDetailsRedux?.user_data?.firstName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        getUserDetailsRedux: state.setUserDetailsReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);

