import React, { useState, useEffect, forwardRef } from "react";
import { image_url } from "Utils/BaseURL";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "Customer/Styles/MainStyles/packageDetails/packageaddonmodal.css";
import "Customer/Styles/MainStyles/profileModal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  uploadImageApi,
  updateUserProfile,
} from "Customer/Apis/MainApis/mainApis";
import { BsFillCameraFill } from "react-icons/bs";
import { connect } from "react-redux";
import "Customer/Styles/MainStyles/imagegallerymodal.css";

function ImageGalleryModal({
  openUpdateImageGalleryModal,
  setOpenUpdateImageGalleryModal,
  singleHotelData,
}) {
  const [featureImage, setFeatureImage] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [allSelectedImages, setAllSelectedImages] = useState();

  useEffect(() => {
    setAllSelectedImages(singleHotelData?.roomImage);
  }, [singleHotelData]);

  return (
    <div>
      <Modal
        centered
        size="xl"
        show={openUpdateImageGalleryModal}
        onHide={() => {
          setOpenUpdateImageGalleryModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Our Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <button
              className={`${
                selectedTab === "1"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("1");
                setAllSelectedImages(singleHotelData?.roomImage);
              }}
            >
              Rooms
            </button>
            <button
              className={`${
                selectedTab === "2"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("2");
                setAllSelectedImages(singleHotelData?.lobbyImage);
              }}
            >
              Lobby
            </button>
            <button
              className={`${
                selectedTab === "3"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("3");
                setAllSelectedImages(singleHotelData?.facadeImage);
              }}
            >
              Facade
            </button>
            <button
              className={`${
                selectedTab === "4"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("4");
                setAllSelectedImages(singleHotelData?.washroomImage);
              }}
            >
              Washroom
            </button>
            <button
              className={`${
                selectedTab === "5"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("5");
                setAllSelectedImages(singleHotelData?.commonAreaImage);
              }}
            >
              Commons
            </button>
            <button
              className={`${
                selectedTab === "6"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("6");
                setAllSelectedImages(singleHotelData?.restaurantsImage);
              }}
            >
              Restaurants
            </button>
            <button
              className={`${
                selectedTab === "7"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("7");
                setAllSelectedImages(singleHotelData?.othersImage);
              }}
            >
              Others
            </button>
            <button
              className={`${
                selectedTab === "8"
                  ? "lists_rooms_btn_active"
                  : "lists_rooms_btn"
              }`}
              onClick={() => {
                setSelectedTab("8");
                setAllSelectedImages(singleHotelData?.receptionImage);
              }}
            >
              Reception
            </button>
          </div>
          <div className="image_gallery_main">
            <div className="image_gallery_main_box">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="list_img_gallery">
                    <div className="card-wrapper">
                      <div className="">
                        <div className="product-imgs">
                          <div className="img-display">
                            <div className="img-showcase">
                              {featureImage?.id === singleHotelData?._id ? (
                                <img
                                  src={`${image_url}${featureImage?.src}`}
                                  alt="img"
                                  className="img-fluid"
                                />
                              ) : allSelectedImages?.length > 0 ? (
                                <img
                                  src={`${image_url}${allSelectedImages[0]?.src}`}
                                  alt="img"
                                  className="img-fluid"
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="img-select">
                            {allSelectedImages?.map((img, i) => {
                              return (
                                <div
                                  key={i}
                                  className="img-item "
                                  onClick={() => {
                                    setFeatureImage({
                                      id: singleHotelData?._id,
                                      src: img?.src,
                                    });
                                  }}
                                >
                                  {img?.src?.length === 0 ? null : (
                                    <img
                                      src={`${image_url}${img?.src}`}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getUserDetailsRedux: state.setUserDetailsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageGalleryModal);
