
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function AboutUsModal({showAboutUsModal, setShowAboutUsModal}) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showAboutUsModal}
                onHide={() => {
                    setShowAboutUsModal(false);
                }}
            >
                <Modal.Header closeButton>
                   About Us
                </Modal.Header>
                <Modal.Body>
                    <h6 className="" >Nalafalhu Holidays, based in the Maldives, is a leading travel agency specializing in both inbound and outbound travel services. We offer reservations at luxury resorts, hotels, and guest houses on inhabited islands, alongside arranging excursions, transfers, diving, and safari trips. Our owners possess 25 years of experience in the tourism industry. One of them, a former managing director of WorldLink Travel since 1993, has extensive expertise in airlines, hotel resort development, owns Cyryx College, and ventures in real estate. With a Master's degree in Business Management and 30 years in business, our dedicated team ensures exceptional service and customer satisfaction from start to finish.  </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowAboutUsModal(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AboutUsModal
