import React, { useState, useEffect,forwardRef } from "react";
import "Customer/Styles/MainStyles/packageDetails/packagemaindetails.css";
import PackageAddOnModal from "Customer/Modals/PackageAddOnModal";
import AllCouponModal from "Customer/Modals/AllCouponModal";
import { getAllAddOnServicesForPackage, getAllCoupons,getCouponsByInput, jungleSafariBookingApi } from "Customer/Apis/MainApis/mainApis";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function JungleSafariMainDetails({ singlePackageData }) {
  const [openPackageAddOnModal, setOpenPackageAddOnModal] = useState(false);
  const [openAllCouponModal, setOpenAllCouponModal] = useState(false);
  const [allAddOns, setAllAddOns] = useState([]);
  const [allCoupons, setAllCoupons] = useState([]);

  const [addOnPrice, setAddOnPrice] = useState([]);
  const [selectedCouponId, setSelectedCouponId] = useState('');

  const [selectedAddOnServices] = useState([]);
  const [selectedCouponsPrice, setSelectedCouponsPrice] = useState(0);

  const [couponInput, setCouponInput] = useState('')

  
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  var tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  useEffect(() => {
    setAddOnPrice(addOnPrice);
  }, [addOnPrice]);

  let totalAddOnPrice = 0

  for (let i = 0; i < addOnPrice.length; i++) {
    totalAddOnPrice += addOnPrice[i];
  }

  useEffect(() => {
    getData();
  }, [openPackageAddOnModal,selectedAddOnServices, openAllCouponModal]);

  const getData = async () => {
    if (singlePackageData?.addOnsServices?.length > 0) {
      getAllAddOnServicesForPackage(
        singlePackageData?.addOnsServices,
        (data) => {
          setAllAddOns(data);
        }
      );
    }

    if (singlePackageData?.coupons?.length > 0) {
      getAllCoupons(
        singlePackageData?.coupons,
        (data) => {
          setAllCoupons(data);
        }
      );
    }
  };

  const handleSelectAddonService = async (id, price) => {
    if (selectedAddOnServices?.includes(id)) {
      let findIndexAutoReply = selectedAddOnServices?.findIndex(
        (val) => val === id
      );
      selectedAddOnServices?.splice(findIndexAutoReply, 1);
      addOnPrice.splice(findIndexAutoReply, 1);
    } else {
      selectedAddOnServices?.push(id);
      addOnPrice?.push(price);
    }
  }

  const handleSelectCoupon = async (id, price) => {
    if (selectedCouponId === id) {
      setSelectedCouponId('')
      setSelectedCouponsPrice(0)
    } else {
      setSelectedCouponId(id);
      setSelectedCouponsPrice(price)
    }
  }

  const ExampleStartingCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      className="w-100"
      onClick={onClick}
      type="text"
      id="maldive_room_date_selector"
      placeholder="Select a date"
      value={startDate}
      onChange={() => {

      }}
    />
  ));

  const ExampleEndingCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      className="w-100"
      onClick={onClick}
      type="text"
      id="maldive_room_date_selector"
      placeholder="Select a date"
      value={endDate}
      onChange={() => {

      }}
    />
  ));


  const handleSubmitBooking = async()=>{
    let user_local_token = localStorage.getItem("maldive_auth_token")
    let body = {
      "jungleSafariId": singlePackageData?._id,
      "vendorId": singlePackageData?.vendorId,
      "total_price":parseInt(singlePackageData?.price + totalAddOnPrice - selectedCouponsPrice),
      "add_on_service": selectedAddOnServices,
      "couponNumber": couponInput,
      "checkInDate": startDate,
      "checkOutDate": endDate
    }
    console.log(body,'body')
    jungleSafariBookingApi(body, user_local_token,(data)=>{

    })
  }

  return (
    <div>
      <AllCouponModal
        openAllCouponModal={openAllCouponModal}
        setOpenAllCouponModal={setOpenAllCouponModal}
        allCoupons={allCoupons}
        selectedCouponId={selectedCouponId}
        handleSelectCoupon={handleSelectCoupon}
        setCouponInput={setCouponInput}
      />
      <PackageAddOnModal
        openPackageAddOnModal={openPackageAddOnModal}
        setOpenPackageAddOnModal={setOpenPackageAddOnModal}
        allAddOns={allAddOns}
        selectedAddOnServices={selectedAddOnServices}
        handleSelectAddonService={handleSelectAddonService}
        selectedCouponsPrice={selectedCouponsPrice}
      />

      <div className="pakcgae_details_main">
        <h2>{singlePackageData?.jungleSafariName}</h2>
        <h4>{singlePackageData?.jungleSafariTitle}</h4>
        <p>{singlePackageData?.jungleSafariShortDescription}</p>
        {singlePackageData?.jungleSafariType?.map((item, i) => {
          return <h6 key={i}>{item?.label}</h6>;
        })}

        <div className="package_description my-3">
          <h4>Package Description</h4>
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: singlePackageData?.description }}
          ></div>
        </div>

        <div className="package_details_main_add_ons">
          <p onClick={() => setOpenPackageAddOnModal(true)}>Add On Services</p>
        </div>

        <div className="my-3 row d-flex align-items-center justify-content-between">
          <div className="col-xxl-4 col-lg-6 col-sm-6 d-flex align-items-center package_details_main_coupons_and_addons">
            <input
              type="text"
              placeholder="Apply Coupon"
              className="form-control w-75"
              value={couponInput}
              onChange={(e) => {
                setCouponInput(e.target.value)
              }}
            />
            <button className="mx-2 package_details_apply_coupon_button"
             onClick={() => {
              let body = {
                "couponName": couponInput
              }
              getCouponsByInput(body, (data) => {
                if (selectedCouponId === data?._id) {
                  setSelectedCouponId('')
                  setSelectedCouponsPrice(0)
                } else {
                  setSelectedCouponId(data?._id);
                  setSelectedCouponsPrice(data?.discountPrice)
                }
              })
            }}
            >
              Apply
            </button>
            <p className="w-100 m-0 p-0" onClick={()=>setOpenAllCouponModal(true)} >View All Coupons</p>
          </div>
          <div className="col-xxl-4 col-lg-6 col-sm-6 d-flex align-items-center package_details_main_price">
            <h4> ${singlePackageData?.price + totalAddOnPrice - selectedCouponsPrice}</h4>
            <h5>$ {singlePackageData?.discountPrice}</h5>
          </div>
        </div>

        <div className='maldives_room_check_availability_box d-xxl-block d-none' >
          <div className="col-6 mb-4">
            <div className="row">
              <h6>Select Date (Which date do you want to go)</h6>
              <div className="col-5">
                <div>
                  <DatePicker
                    minDate={new Date()}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleStartingCustomInput />}
                  />
                </div>
                <div className="calendar" id="calendar">
                  <div className="header">
                    <button id="prevBtn">&lt;</button>
                    <h2 id="monthYear">Month Year</h2>
                    <button id="nextBtn">&gt;</button>
                  </div>
                  <div className="days" id="daysContainer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div>
          <button onClick={()=>handleSubmitBooking()} className="package_book_now_button">Book Now</button>
        </div>
      </div>
    </div>
  );
}

export default JungleSafariMainDetails;
