import React, { useEffect, useState } from 'react';
import { Table, Badge, Container, Pagination } from 'react-bootstrap';
import { getMyHotelBookingApi, getMyPacakgeBookingApi, getMyJungleSafariBookingApi } from 'Customer/Apis/MainApis/mainApis';
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner';
import moment from 'moment';

const BookingTable = () => {
    const [selectedTab, setSelectedTab] = useState('1')
    const [hotelBookings, setHotelBookings] = useState([]);
    const [packageBookings, setPackageBookings] = useState([]);
    const [jungleBookings, setJungleBookings] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPackagePage, setCurrentPackagePage] = useState(1);
    const [currentJunglePage, setCurrentJunglePage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentHotelBookings = hotelBookings?.slice(indexOfFirstItem, indexOfLastItem);


    const indexOfLastItem1 = currentPackagePage * itemsPerPage;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage;
    const currentPackageBookings = packageBookings?.slice(indexOfFirstItem1, indexOfLastItem1);


    const indexOfLastItem2 = currentPackagePage * itemsPerPage;
    const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage;
    const currentJungleBookings = jungleBookings?.slice(indexOfFirstItem2, indexOfLastItem2);


    // Handle pagination click
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const paginatePackage = (pageNumber) => setCurrentPackagePage(pageNumber);
    const paginateJungle = (pageNumber) => setCurrentJunglePage(pageNumber);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'Confirmed':
                return <Badge bg="success">Confirmed</Badge>;
            case 'pending':
                return <Badge bg="warning" text="dark">Pending</Badge>;
            case 'Cancelled':
                return <Badge bg="danger">Cancelled</Badge>;
            default:
                return <Badge bg="secondary">Unknown</Badge>;
        }
    };

    useEffect(() => {
        getBooking()
    }, [selectedTab])
    const getBooking = async () => {
        let user_local_token = localStorage.getItem("maldive_auth_token")
        getMyHotelBookingApi(user_local_token, currentPage, (data) => {
            setHotelBookings(data?.data)
        })
        getMyPacakgeBookingApi(user_local_token, currentPage, (data) => {
            setPackageBookings(data?.data)
        })
        getMyJungleSafariBookingApi(user_local_token, currentPage, (data) => {
            setJungleBookings(data?.data)
        })
    }

    return (
        <>
            <HomeBanner />
            <div className="mt-4 mx-5" >
                <div className="d-flex align-items-center ">
                    <button className={selectedTab === "1" ? 'active_tab_orde_table' : "inactive_tab_orde_table"} onClick={() => setSelectedTab('1')} >Hotels</button>
                    <button className={selectedTab === "2" ? 'active_tab_orde_table' : "inactive_tab_orde_table"} onClick={() => setSelectedTab('2')} >Packages</button>
                    <button className={selectedTab === "3" ? 'active_tab_orde_table' : "inactive_tab_orde_table"} onClick={() => setSelectedTab('3')} >Jungle Safari</button>
                </div>
            </div>
            {
                selectedTab === '1'
                    ?
                    <div className="my-5 mx-5">
                        <h2 className="text-center mb-4">My Hotel Booking Details</h2>
                        <Table striped bordered hover>
                            <thead className="table-dark">
                                <tr>
                                    <th>Booking ID</th>
                                    <th>Location</th>
                                    <th>Action</th>
                                    <th>Check-In Date</th>
                                    <th>Check-Out Date</th>
                                    <th>Adults</th>
                                    <th>Children</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentHotelBookings.map((booking, i) => (
                                    <tr key={i}>
                                        <td>{booking?._id}</td>
                                        <td>{booking?.hotel?.address}</td>
                                        <td>{booking?.status === "pending" ? <Badge bg="danger">Cancel Booking</Badge> : <p>{booking?.status}</p>}</td>
                                        <td>{moment(booking?.checkInDate).format("MM-DD-YYYY")}</td>
                                        <td>{moment(booking?.checkOutDate).format("MM-DD-YYYY")}</td>
                                        <td>{booking?.totalAdult}</td>
                                        <td>{booking?.totalChild}</td>
                                        <td>{getStatusBadge(booking?.status)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Pagination>
                            {[...Array(Math.ceil(hotelBookings.length / itemsPerPage)).keys()].map((number) => (
                                <Pagination.Item

                                    key={number + 1}
                                    active={number + 1 === currentPage}
                                    onClick={() => paginate(number + 1)}
                                >
                                    {number + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </div>
                    :
                    selectedTab === '2'
                        ?
                        <div className="my-5 mx-5">
                            <h2 className="text-center mb-4">My Packages Booking Details</h2>
                            <Table striped bordered hover>
                                <thead className="table-dark">
                                    <tr>
                                        <th>Booking ID</th>
                                        <th>Location</th>
                                        <th>Action</th>
                                        <th>Boarding Date</th>
                                        <th>Persons</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPackageBookings.map((booking, i) => (
                                        <tr key={i}>
                                            <td>{booking?._id}</td>
                                            <td>{booking?.package_details?.address}</td>
                                            <td>{booking?.status === "pending" ? <Badge bg="danger">Cancel Booking</Badge> : <p>{booking?.status}</p>}</td>
                                            <td>{moment(booking?.checkInDate).format("MM-DD-YYYY")}</td>
                                            <td>{booking?.package_details?.totalMembers}</td>
                                            <td>{booking?.total_price}</td>
                                            <td>{getStatusBadge(booking?.status)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination>
                                {[...Array(Math.ceil(packageBookings.length / itemsPerPage)).keys()].map((number) => (
                                    <Pagination.Item

                                        key={number + 1}
                                        active={number + 1 === currentPackagePage}
                                        onClick={() => paginatePackage(number + 1)}
                                    >
                                        {number + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                        :
                        <div className="my-5 mx-5">
                            <h2 className="text-center mb-4">My Jungle Safari Booking Details</h2>
                            <Table striped bordered hover>
                                <thead className="table-dark">
                                    <tr>
                                        <th>Booking ID</th>
                                        <th>Location</th>
                                        <th>Action</th>
                                        <th>Boarding Date</th>
                                        <th>Persons</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPackageBookings.map((booking, i) => (
                                        <tr key={i}>
                                            <td>{booking?._id}</td>
                                            <td>{booking?.package_details?.address}</td>
                                            <td>{booking?.status === "pending" ? <Badge bg="danger">Cancel Booking</Badge> : <p>{booking?.status}</p>}</td>
                                            <td>{moment(booking?.checkInDate).format("MM-DD-YYYY")}</td>
                                            <td>{booking?.package_details?.totalMembers}</td>
                                            <td>{booking?.total_price}</td>
                                            <td>{getStatusBadge(booking?.status)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination>
                                {[...Array(Math.ceil(jungleBookings.length / itemsPerPage)).keys()].map((number) => (
                                    <Pagination.Item

                                        key={number + 1}
                                        active={number + 1 === currentJunglePage}
                                        onClick={() => paginateJungle(number + 1)}
                                    >
                                        {number + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
            }
        </>
    );
};

export default BookingTable;
