import { useState, useEffect } from 'react'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import ScheduleCalender from 'Customer/Layouts/HomeLayouts/ScheduleCalender'
import AllOffers from 'Customer/Layouts/HomeLayouts/AllOffers'
import HolidayPackages from 'Customer/Layouts/HomeLayouts/HolidayPackages'
import HomeSegment from 'Customer/Layouts/HomeLayouts/HomeSegment'
import TopDestination from 'Customer/Layouts/HomeLayouts/TopDestination'
import TravelWithUs from 'Customer/Layouts/HomeLayouts/TravelWithUs'
import MostPopularResort from 'Customer/Layouts/HomeLayouts/MostPopularResort'
import ClientTestimonials from 'Customer/Layouts/HomeLayouts/ClientTestimonials'
import BlogsAndArticles from 'Customer/Layouts/HomeLayouts/BlogsAndArticles'
import Footer from 'Customer/Components/Footer'
import { getAllOffersApi, getAllServicesApi, getAllPackagessApi, getAllHotelsApi,getAllBlogsApi } from 'Customer/Apis/MainApis/mainApis'
import { getUserDetailsApi } from 'Customer/Apis/AuthApis/authApis'
import { setUserDetails } from '../../../Redux/actions'
import { connect } from 'react-redux'

function HomePage({ setUserDetailsRedux }) {
  const [selectedTab, setSelectedTab] = useState('')
  const [allOffersList, setAllOffersList] = useState([])
  const [allServicesList, setAllServicesList] = useState([])
  const [allPackageList, setAllPackageList] = useState([])
  const [allHotelsList, setAllHotelsList] = useState([])

  const [allBlogsList, setAllBlogsList] = useState([])

  useEffect(() => {
    getAllOffers()
    getAllService()
    getAllPackage()
    getAllHotels()
    getUserDetails()
    getBlogDetails()
  }, [])

  const getAllPackage = async ()=>{
    getAllPackagessApi(1, (data)=>{
      setAllPackageList(data)
    })
  }
  const getAllHotels = async ()=>{
    getAllHotelsApi(1, (data)=>{
      setAllHotelsList(data)
    })
  }

  const getAllService  = async ()=>{
    getAllServicesApi(1,(data)=>{
      setAllServicesList(data)
    })
  }

  const getAllOffers = async () => {
    getAllOffersApi(1, "", (data) => {
      setAllOffersList(data?.data)
    })
  }

  const handleChangeOfferType = async(type)=>{
    getAllOffersApi(1, type, (data) => {
      setAllOffersList(data?.data)
    })
  }

  const getUserDetails = async ()=>{
    let user_local_token = localStorage.getItem("maldive_auth_token")
    if(user_local_token){
      getUserDetailsApi(user_local_token, (data)=>{
        setUserDetailsRedux({user_data:data, user_token:user_local_token})
      })
    }
  }

  const getBlogDetails = async ()=>{
      getAllBlogsApi(1, (data)=>{
        setAllBlogsList(data?.data)
      })
  }
  

  return (
    <div>
      <HomeBanner  />
      <ScheduleCalender  />
      <AllOffers allOffersList={allOffersList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} handleChangeOfferType={handleChangeOfferType} />
      <HolidayPackages allPackageList={allPackageList} />
      <HomeSegment />
      <TopDestination  allPackageList={allPackageList}  />
      <TravelWithUs />
      <MostPopularResort allHotelsList={allHotelsList} />
      <ClientTestimonials allHotelsList={allHotelsList} />
      <BlogsAndArticles allBlogsList={allBlogsList} />
      <Footer />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    getUserDetailsRedux: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetailsRedux: (parameter) => {
      dispatch(setUserDetails(parameter));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

