import React,{useEffect, useState} from 'react'
import { getAllBlogsApi } from 'Customer/Apis/MainApis/mainApis'
import { image_url } from 'Utils/BaseURL'

function BlogDetailsPage() {
    const [singleBlog, setSingleBlog] = useState()

    useEffect(()=>{
        getBlogDetails()
    },[])

    const getBlogDetails = async ()=>{
        let pathName = window.location.pathname.split("/")[3]

        getAllBlogsApi(1, (data)=>{
            let findIndex = data?.data?.findIndex(
                (val) => val?._id === pathName
              );
              let findData = data?.data?.splice(findIndex,1)
              setSingleBlog(...findData)
        })
    }

    console.log(singleBlog,'singleBlog')
  return (
    <div>
       <img width="100%" height={700} src={`${image_url}${singleBlog?.blog_image}`} alt="img" />
        <div className="p-5">
        <h4>{singleBlog?.blog_title}</h4>
       <div className="post__content" dangerouslySetInnerHTML={{__html: singleBlog?.description}}></div>
        </div>

    </div>
  )
}

export default BlogDetailsPage
