
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function LuxuryHotelModal({ showLuxuryHotel, setShowLuxuryHotel }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showLuxuryHotel}
                onHide={() => {
                    setShowLuxuryHotel(false);
                }}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h6>The Maldives is a celebrated holiday destination, renowned for its stunning landscapes and opulent resorts. Home to nearly all top luxury brands, the islands have become a must-have presence for international names aiming to serve their prestigious clientele. Esteemed brands such as One & Only, Hilton, Sheraton, Banyan Tree, Soneva, Four Seasons, W Marriott, Shangri-La, Crossroads, and Taj offer unparalleled luxury and service against the backdrop of turquoise waters and pristine beaches. As more luxury hotel chains establish themselves here, the Maldives continues to set the standard for extraordinary hospitality and unforgettable experiences. </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowLuxuryHotel(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LuxuryHotelModal
