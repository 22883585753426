import { useState, useEffect, forwardRef } from "react";
import "Customer/Styles/MainStyles/ScheduleCalender.css";
import { FaPlus, FaMinus } from "react-icons/fa";
import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

function ScheduleCalender({ allServicesList }) {
  const navigation = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [numberOfAdult, setNumberOfAdult] = useState(1);
  const [numberOfChild, setNumberOfChild] = useState(1);
  const [lat, setLat] = useState(31.041755);
  const [lng, setLng] = useState(78.038153);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyAKfvrGBxXsxJ2AovOGAdltyorLy4ytT1I",
    onPlaceSelected: (place) => {
      console.log(place?.geometry?.location?.lat(), "placeeeeeeee");
      setLat(place?.geometry?.location?.lat());
      setLng(place?.geometry?.location?.lng());
    },
  });

  const ExampleStartingCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      className="w-100"
      onClick={onClick}
      type="text"
      id="dateInput"
      placeholder="Select a date"
      value={startDate}
      onChange={()=>{
        
      }}
    />
  ));

  const ExampleEndingCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      className="w-100"
      onClick={onClick}
      type="text"
      id="dateInput"
      placeholder="Select a date"
      value={endDate}
      onChange={()=>{

      }}
    />
  ));

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem('starting_date',startDate)
    localStorage.setItem('ending_date',endDate)
    navigation(`search-hotel/${lat}/${lng}/numberOfAdult=${numberOfAdult}/numberOfChild=${numberOfChild}`)
  };

  return (
    <>
      <section className="choose_trip_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="choose_trip_content">
                <span>Choose your Trip</span>
                <div className="sec_heading">
                  <h2>Start your Vacation Now</h2>
                </div>
                <p>
                Choose your dream trip to the Maldives with Nalafalhu Holidays, where paradise awaits. Our expert team curates unforgettable experiences, offering reservations at luxurious resorts, stunning hotels, and charming guest houses on inhabited islands. Whether you seek adventure or relaxation, we arrange a variety of excursions, seamless transfers, exhilarating diving adventures, and serene safari trips. With over 25 years of industry expertise, we excel in creating personalized journeys that ensure exceptional service and satisfaction. Discover the breathtaking beauty and vibrant culture of the Maldives, knowing your travel needs are in capable hands. Let us make your Maldivian getaway truly extraordinary. 
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-12 d-flex justify-content-center">
              <div className="choose_trip_form">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="row lower_choose_trip_form">
                    <div className="col-12">
                      <label htmlFor="exampleCheckIn" className="form-label">
                        Location
                      </label>
                      <input
                        ref={ref}
                        className="form-control me-2"
                        type="search"
                        placeholder="location"
                        aria-label="Search"
                        onChange={(e) => {
                        }}
                      />
                    </div>
                    
                    <div className="col-6">
                      <label htmlFor="exampleCheckIn" className="form-label">
                        Check-In Date
                      </label>

                      <div>
                        <DatePicker
                          minDate={new Date()}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          customInput={<ExampleStartingCustomInput />}
                        />
                      </div>
                      <div className="calendar" id="calendar">
                        <div className="header">
                          <button id="prevBtn">&lt;</button>
                          <h2 id="monthYear">Month Year</h2>
                          <button id="nextBtn">&gt;</button>
                        </div>
                        <div className="days" id="daysContainer"></div>
                      </div>
                    </div>

                    <div className="col-6">
                      <label htmlFor="exampleCheckIn" className="form-label">
                        Check-Out Date
                      </label>
                      <div>
                        <DatePicker
                          minDate={new Date()}
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          customInput={<ExampleEndingCustomInput />}
                        />
                      </div>
                      <div className="calendar" id="calendar">
                        <div className="header">
                          <button id="prevBtn">&lt;</button>
                          <h2 id="monthYear">Month Year</h2>
                          <button id="nextBtn">&gt;</button>
                        </div>
                        <div className="days" id="daysContainer"></div>
                      </div>
                    </div>

                    <div className="col-4">
                      <label htmlFor="exampleCheckIn2" className="form-label">
                        Adults
                      </label>
                      <div className="number">
                        <span className="minus">
                          <FaMinus
                            onClick={() => {
                              if (numberOfChild <= 1) {
                                setNumberOfAdult(1);
                              } else {
                                setNumberOfAdult(numberOfAdult - 1);
                              }
                            }}
                          />
                        </span>
                        <span>{numberOfAdult}</span>
                        <span className="plus">
                          <FaPlus
                            onClick={() => {
                              if (numberOfAdult >= 10) {
                                setNumberOfAdult(numberOfAdult);
                              } else {
                                setNumberOfAdult(numberOfAdult + 1);
                              }
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="col-4">
                      <label htmlFor="exampleCheckIn2" className="form-label">
                        Childs
                      </label>
                      <div className="number">
                        <span className="minus">
                          <FaMinus
                            onClick={() => {
                              if (numberOfChild <= 1) {
                                setNumberOfChild(1);
                              } else {
                                setNumberOfChild(numberOfChild - 1);
                              }
                            }}
                          />
                        </span>
                        <span>{numberOfChild}</span>
                        <span className="plus">
                          <FaPlus
                            onClick={() => {
                              if (numberOfChild >= 4) {
                                setNumberOfChild(numberOfChild);
                              } else {
                                setNumberOfChild(numberOfChild + 1);
                              }
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="col-12 ">
                      <button type="submit" className="choose_trip_form_btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ScheduleCalender;
