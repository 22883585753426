
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function MaldiveCultureModal({ showMaldiveCulture, setShowMaldiveCulture }) {

    return (
        <div>
            <Modal
                centered
                show={showMaldiveCulture}
                onHide={() => {
                    setShowMaldiveCulture(false);
                }}
            >
                <Modal.Header closeButton>
                    Maldives culture
                </Modal.Header>
                <Modal.Body>
                    <h6>The Maldives offers a unique blend of natural beauty and vibrant culture, which we actively promote through immersive experiences. By introducing guest houses on inhabited islands and the innovative 'eroaming' homestay options, we invite tourists to live with locals and truly engage with the Maldivian way of life. These initiatives provide a window into our rich cultural heritage, where visitors can participate in traditional activities such as Boduberu music performances and Eid Kulhivaru celebrations. These experiences allow travelers to connect deeply with the Maldivian culture, making their stay both enriching and memorable.  </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowMaldiveCulture(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MaldiveCultureModal
