import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import 'Customer/Styles/MainStyles/HomeBanner.css'
import { connect } from 'react-redux'
import { FaRegUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { IoBagCheckOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ProfileModal from 'Customer/Modals/ProfileModal';
import { image_url } from 'Utils/BaseURL';
import { useNavigate } from 'react-router-dom';
import { MdDashboard } from "react-icons/md";

function HomeBanner({ getUserDetailsRedux }) {
    let navigation =useNavigate()
    const [isLogin, setIsLogin] = useState(false)
    const [showProfileDropdown, setShowProfileDropdown] = useState(false)
    const [openUpdateProfileModal, setOpenUpdateProfileModal] = useState(false)

    useLayoutEffect(() => {
        let user_local_token = localStorage.getItem("maldive_auth_token")
        setIsLogin(user_local_token)
    }, [])

    const handleLogout = () => {
        localStorage.removeItem("maldive_auth_token")
        setIsLogin(false)
        setShowProfileDropdown(false);
    }

    const closeProfileDropdown = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeProfileDropdown.current && !closeProfileDropdown.current.contains(event.target)) {
                setShowProfileDropdown(false);
            }
        };

        if (showProfileDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showProfileDropdown]);

    return (
        <>
            <ProfileModal openUpdateProfileModal={openUpdateProfileModal} setOpenUpdateProfileModal={setOpenUpdateProfileModal} />
            <section className="hero_section_wrapper">
                <div className="container-xl container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="navbar">
                                <div className="nav-logo">
                                    <Link to="/"><img src="/img/logo.png" alt="" /></Link>
                                </div>
                                <div className="nav-items ms-auto">
                                    <ul className='m-0 p-0' >
                                        <li><Link to="/"> Home </Link></li>
                                        <li><Link to="/customer/hotels-listing"> Holiday Resorts </Link></li>
                                        <li><Link to="/customer/packages-listing"> Holiday Packages </Link></li>
                                        <li><Link to="/customer/jungle-safari-listing"> Jungle Safari </Link></li>
                                    </ul>
                                </div>
                                <div className='position-relative' ref={closeProfileDropdown}>
                                    <div className="nav-button " >
                                        {/* <div className="anim-layer"></div> */}
                                        {isLogin
                                            ?
                                            <div className='d-flex align-items-center px-3' onClick={() => setShowProfileDropdown(!showProfileDropdown)} >
                                                <button className='logout_button' >{getUserDetailsRedux?.user_data?.firstName}</button>
                                                <img width={30} height={30} src={`${image_url}${getUserDetailsRedux?.user_data?.profileImg}`} alt="" />
                                            </div>
                                            :
                                            <Link to="/login">Agent / Customer</Link>
                                        }
                                    </div>
                                    {
                                        showProfileDropdown
                                            ?
                                            <div className='home-nav-profile-dropdown' >
                                                {
                                                    getUserDetailsRedux?.user_data?.role==='agent'
                                                    ?
                                                    <p className='d-flex align-items-center' onClick={()=> navigation('/agent/dashboard')} ><MdDashboard className='mx-2' />Dashboard</p>
                                                    :
                                                    null
                                                }
                                                <p className='d-flex align-items-center' onClick={()=>setOpenUpdateProfileModal(true)} ><FiUser className='mx-2' />Profile</p>
                                                <p className='d-flex align-items-center'
                                                onClick={()=>{
                                                    navigation('/my-orders')
                                                }}
                                                ><IoBagCheckOutline className='mx-2' /> My Orders</p>
                                                <p className='d-flex align-items-center' onClick={handleLogout}><IoMdLogOut className='mx-2' /> Logout</p>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                <div id="hamburger-menu">&#9776;</div>
                            </div>
                            <div id="mobile-menu">
                                <div className="mobile-nav-items">
                                    <ul>
                                        <li><Link to="/"> Home </Link></li>
                                        <li><Link to="/customer/hotels-listing"> Holiday Resorts </Link></li>
                                        <li><Link to="/customer/packages-listing"> Holiday Packages </Link></li>
                                        <li><Link to="/customer/jungle-safari-listing"> Jungle Safari </Link></li>
                                    </ul>
                                </div>
                                <div className="mobile-nav-button">
                                    <div className="anim-layer"></div>
                                    {isLogin
                                        ?
                                        <a href="/">Logout</a>
                                        :
                                        <a href="/">Login/Registration</a>
                                    }
                                </div>
                                <div id="hamburger-cross"><i className="fa-solid fa-xmark"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <div className="hero_sec_content">
                                <h1>Welcome To Maldives Holidays</h1>
                                <p>Welcome to the Maldives, your one-stop solution for the ultimate holiday experience. Contact us to discover unbeatable offers tailored to your dream getaway. Our dedicated team is ready to assist you with personalized travel plans, ensuring unforgettable memories in this tropical paradise. Reach out to us today and embark on your next best holiday adventure. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        getUserDetailsRedux: state.setUserDetailsReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner);


