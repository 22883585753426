
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function MaldiveVisaModal({ showMaldiveVisa, setShowMaldiveVisa }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showMaldiveVisa}
                onHide={() => {
                    setShowMaldiveVisa(false);
                }}
            >
                <Modal.Header closeButton>
                    Covid 19 Update
                </Modal.Header>
                <Modal.Body>
                    <h6>The Maldives welcomes visitors with a convenient visa policy. Upon arrival, tourists receive a 30-day free entry visa, stamped on their passport without any fees. If you wish to extend your stay, your hotel can assist with the necessary immigration formalities.
                    </h6>
                    <h6>For business travelers attending meetings across the Maldives and staying longer than 30 days, obtaining a business visa is advisable. This can be arranged before arrival with the help of your travel agency or hotel. </h6>
                    <h6>It is important to note that tourists on a tourist visa must stay overnight in facilities registered with the Ministry of Tourism. For those looking to work in the Maldives, a work permit is required. This process takes a few weeks and involves submitting necessary docume </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowMaldiveVisa(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MaldiveVisaModal
