import React, { useState } from 'react'
import 'Customer/Styles/MainStyles/resortDetails/resortguestreview.css'
import { BsCloudUpload } from "react-icons/bs";
import { Rating } from 'react-simple-star-rating'
import { uploadImageApi, postRatingApi } from 'Customer/Apis/MainApis/mainApis';
import { image_url } from 'Utils/BaseURL';

function PackageGuestReview({ singlePackageData, singlePackageRatings, totalRatings }) {

  const [rating, setRating] = useState(0)
  const [description, setDescription] = useState('')
  const [image_1, setImage_1] = useState('')
  const [image_2, setImage_2] = useState('')
  const [image_3, setImage_3] = useState('')
  const [image_4, setImage_4] = useState('')

  const handleRating = (rate) => {
    setRating(rate)
  }
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)

  const handleUploadImage = async (image, path) => {
    if (image === '1') {
      uploadImageApi(path, (data) => {
        setImage_1(data)
      })
    } else if (image === '2') {
      uploadImageApi(path, (data) => {
        setImage_2(data)
      })
    } else if (image === '3') {
      uploadImageApi(path, (data) => {
        setImage_3(data)
      })
    } else if (image === '4') {
      uploadImageApi(path, (data) => {
        setImage_4(data)
      })
    }
  }

  const handleSubmitRating = async () => {
    let user_local_token = localStorage.getItem("maldive_auth_token")
    let body = {
      "product_id": singlePackageData?._id,
      "rating": rating,
      "description": description,
      "image_1": image_1,
      "image_2": image_2,
      "image_3": image_3,
      "image_4": image_4,
      "image_5": image_4
    }
    postRatingApi(user_local_token, body)
  }
  

  return (
    <div>
      <section className="reviews_sec_wrapper mb-5" id="reviews">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <div className="review_wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="detail_tabs_heading d-flex justify-content-between">
                      <h2>Guest Reviews</h2>
                      <a href="#">See all reviews</a>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="rating_bar_wrapper">
                      <div className="rating_content">
                        <h4>Rating</h4>
                        <p>{parseInt(Number(totalRatings).toFixed(1))}/5</p>
                        <span>{singlePackageRatings?.length} Ratings</span>
                        {/* <span>206 Reviews</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {
                      singlePackageRatings?.map((item, i) => {
                        return <div key={i} className="review_sec_wrapper">
                          <div className="review_sec">
                            <div className="row">
                              <div className="col-12">
                                <div className="review_header">
                                  <div className="review_sec_img">
                                    <img src={`${image_url}${item?.userDetails?.profileImg}`} alt="img" />
                                  </div>
                                  <div className="review_heading">
                                    <div className="review_main_heading">
                                      <h4>{item?.userDetails?.firstName}{" "}{item?.userDetails?.lastName}</h4>
                                      <span>({item?.createdAt})</span>
                                    </div>
                                    <div className="review_sub_heading">
                                      <span>{item?.userDetails?.email} | 1 Review Written</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="review_content">
                                  <p>{item?.description}</p>
                                </div>
                              </div>
                              <Rating
                                onClick={handleRating}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                onPointerMove={onPointerMove}
                                initialValue={item?.rating}
                                size={20}
                              />

                              <div className="col-12">
                                <div className="review_content_img_wrapper">
                                  <div className="review_content_img">
                                     <img src={`${image_url}${item?.image_1}`} alt="img" />
                                  </div>

                                  <div className="review_content_img">
                                     <img src={`${image_url}${item?.image_2}`} alt="img" />
                                  </div>

                                  <div className="review_content_img">
                                     <img src={`${image_url}${item?.image_3}`} alt="img" />
                                  </div>

                                  <div className="review_content_img">
                                     <img src={`${image_url}${item?.image_4}`} alt="img" />
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                      })
                    }

                  </div>

                  <div className="col-12">
                    <div className="detail_tabs_heading d-flex justify-content-between align-items-baseline py-3">
                      <h2>Write your Reviews</h2>
                      <div className="review_rating_star d-flex align-items-center">
                        <h5 className='m-0' >Ratings</h5>
                        <ul className="d-flex m-0 p-0">
                          <li><img src="/img/colored-star.png" alt="star_img" /></li>
                          <li><img src="/img/colored-star.png" alt="star_img" /></li>
                          <li><img src="/img/colored-star.png" alt="star_img" /></li>
                          <li><img src="/img/colored-star.png" alt="star_img" /></li>
                          <li><img src="/img/simple-star.png" alt="star_img" /></li>
                        </ul>
                      </div>
                    </div>

                    <div className="review_writting_sec">
                      <div>
                        <Rating
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                        />
                      </div>
                      <div className="form-floating mt-3">
                        <textarea className="form-control"
                          placeholder="Leave a comment here"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        >
                        </textarea>
                        <label htmlFor="floatingTextarea2">Would you like to write anything about this Holidays</label>
                      </div>
                      <div className="review_writting_btn d-flex my-4 align-items-center justify-content-between">
                        <div className="d-flex maldives_resort_details_review_upload_file_button">
                          <ul className="d-flex m-0 p-0">
                            <li>
                              <label htmlFor="first_img">
                                {
                                  image_1 === ''
                                    ?
                                    <BsCloudUpload />
                                    :
                                    <img src={`${image_url}${image_1}`} alt="star_img" />
                                }

                              </label>
                              <input type="file" id="first_img"
                                onChange={(e) => {
                                  handleUploadImage('1', e.target.files[0])
                                }}
                              />
                            </li>
                            <li>
                              <label htmlFor="second_img">
                                {
                                  image_2 === ''
                                    ?
                                    <BsCloudUpload />
                                    :
                                    <img src={`${image_url}${image_2}`} alt="star_img" />
                                }
                              </label>
                              <input type="file" id="second_img"
                                onChange={(e) => {
                                  handleUploadImage('2', e.target.files[0])
                                }}
                              />
                            </li>
                            <li>
                              <label htmlFor="third_img">
                                {
                                  image_3 === ''
                                    ?
                                    <BsCloudUpload />
                                    :
                                    <img src={`${image_url}${image_3}`} alt="star_img" />
                                }
                              </label>
                              <input type="file" id="third_img"
                                onChange={(e) => {
                                  handleUploadImage('3', e.target.files[0])
                                }}
                              />
                            </li>
                            <li>
                              <label htmlFor="forth_img">
                                {
                                  image_4 === ''
                                    ?
                                    <BsCloudUpload />
                                    :
                                    <img src={`${image_url}${image_4}`} alt="star_img" />
                                }
                              </label>
                              <input type="file" id="forth_img"
                                onChange={(e) => {
                                  handleUploadImage('4', e.target.files[0])
                                }} />
                            </li>
                          </ul>
                        </div>

                        <div className="word_limits">
                          <p>2000 characters remaining</p>
                        </div>
                      </div>

                      <button className="submit_btn_rating" onClick={() => handleSubmitRating()} >Submit Review</button>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PackageGuestReview
