import { useEffect, useState } from 'react'
import 'Customer/Styles/MainStyles/resortListingFilter.css'
import { FiSearch } from "react-icons/fi";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Form } from "react-bootstrap";
import { getAllCategriesApi } from 'Customer/Apis/MainApis/mainApis';
import { usePlacesWidget } from "react-google-autocomplete";


function ResortFilters({setSelectedPropertyType,setSelectedMinPrice,setSelectedMaxPrice,setLatitute,setLongitute}) {
    const [allPropertyType, setAllPropertyType] = useState([])
    const [address, setAddress] = useState('')
    const priceOptions = [
        { label: "500", value: "500" },
        { label: "1000", value: "1000" },
        { label: "5000 ", value: "5000" },
        { label: "10000", value: "10000" },
        { label: "20000", value: "20000" },
        { label: "50000 ", value: "50000" },
        { label: "1L", value: "1L" }
    ];

    useEffect(() => {
        getAllPropertyListing()
    }, [])

    const getAllPropertyListing = async () => {
        getAllCategriesApi(0, (data) => {
            setAllPropertyType(data[1]?.SubServices)
        })
    }
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyAKfvrGBxXsxJ2AovOGAdltyorLy4ytT1I",
        onPlaceSelected: (place) => {
            setLatitute(place?.geometry?.location?.lat());
            setLongitute(place?.geometry?.location?.lng());
        }
    });

    return (
        <>
            <div className="col-12 maldives_filters_resort_main">

                <div className="content_sidebar">
                    <div className="row">
                        <div className="col-12">
                            <div className="maldives_resort_filters_heading d-flex justify-content-between align-items-center">
                                <h4 className='m-0 p-0' >FILTERS</h4>
                                <span>CLEAR</span>
                            </div>

                            <hr className="my-3" />
                            <div className="">
                                <h5>Location</h5>
                                <div className="maldives_resort_filters_location_search_input ">
                                <input
                                ref={ref}
                                type="text"
                                style={{ height: "40px", border: "1px solid #ddd", borderRadius: "6px" , fontSize:"16px", outline:"none"}}
                                placeholder="Search Loction"
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                    
                                }}
                            />
                                </div>
                            </div>
                            <hr className="my-3" />

                            <div className="">
                                <h5>Price</h5>
                                <div className=" ">
                                    <MultiSelect
                                        placeholder="Min Price"
                                        singleSelect={true}
                                        customValue={true}
                                        onChange={(val) => setSelectedMinPrice(val)}
                                        options={priceOptions}
                                        style={{ width: "100%" }}
                                    />
                                    <h6 className='text-center my-2' >To</h6>
                                    <MultiSelect
                                        placeholder="Max Price"
                                        singleSelect={true}
                                        customValue={true}
                                        onChange={(val) => setSelectedMaxPrice(val)}
                                        options={priceOptions}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="">
                                <h5>Ratings</h5>
                                <div className="maldives_resort_filters_location_search_input ">
                                    <Form>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>5.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <span>(50)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>4.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(56)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>3.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(13)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>2.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(980)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>0</span>
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(980)</span>
                                            </Form.Label>
                                        </Form.Check>
                                    </Form>
                                </div>
                            </div>

                            <hr className="my-3" />
                            <div className="">
                                <h5>Property Type</h5>
                                <div className="maldives_resort_filters_location_search_input ">

                                    <select className='resort_filter_property_type_dropdown'
                                        onChange={(e) => {
                                            setSelectedPropertyType(e.target.value)
                                        }} >
                                        {
                                            allPropertyType?.map((item, i) => {
                                                return <option key={i} value={item?.subServiceName}>{item?.subServiceName}</option>
                                            })
                                        }

                                    </select>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ResortFilters
