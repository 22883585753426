import React from 'react'
import TopBar from 'Agent/layouts/TopBar'

function AllTransactions() {
    return (
        <div>
            <div class="dashbord_content_wrapper">
                <div class="row">
                    <div class="col-12">
                        <TopBar/>
                    </div>

                    <div class="col-12">
                        <div class="dashbord_content_btn_wrapper">
                            <button class="btn"><a href="#"><img src="/img/dashboard-filters.png" alt="#" />Custom Date</a></button>
                            <div class="dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <a href="#"> Newest</a>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="dashboard_content h-auto">
                            <div class="customer_booking_table">
                                <table class="table">
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid rgba(28, 27, 31, 0.5)" }}>
                                            <th scope="col">Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Method</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row">Mr. Mehta</td>
                                            <td>22 June 2024</td>
                                            <td>Paypal</td>
                                            <td>15165 $</td>
                                            <td> <button class="btn btn_confirm"><a href="#">Completed</a></button> </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllTransactions
