import React, { useState, useEffect } from 'react'
import PackagesFilters from 'Customer/Layouts/PackagesListingLayouts/PackagesFilters'
import PackagesListingLayout from 'Customer/Layouts/PackagesListingLayouts/PackagesListingLayout'

import ResortFilters from 'Customer/Layouts/ResortListingLayouts/ResortFilters'
import ResortListingLayout from 'Customer/Layouts/ResortListingLayouts/ResortListingLayout'

import JungleSafariFilters from 'Customer/Layouts/JungleSafariListingLayouts/JungleSafariFilters'
import JungleSafariListingLayout from 'Customer/Layouts/JungleSafariListingLayouts/JungleSafariListingLayout'

import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getAllSegmentHomeApi } from 'Customer/Apis/MainApis/mainApis'


function SegmentDetailsPage() {
    const [allPackageListing, setAllPackagesListing] = useState([])
    const [whichPageIs, setWhichPageIs] = useState('')
    const [page, setPage] = useState(1)

    useEffect(() => {
        getAllPackages()
    }, [page])

    const getAllPackages = async () => {
        let subServiceId = window.location.pathname.split("/")[2]
        console.log(subServiceId, 'subServiceIdsubServiceIdsubServiceId')
        getAllSegmentHomeApi(page, subServiceId, (data) => {
            setAllPackagesListing(data?.data)
            setWhichPageIs(data?.slug)
        })
    }


    return (
        <div>
            <HomeBanner />

            <section className="mx-5 px-5 mt-4">
                <div className="container-fluid">
                    <div className="row">
                        < div className="col-12">
                            <div className="">

                                {
                                    whichPageIs === 'hotel'
                                        ?
                                        <div className="row">
                                            <div className="col-2">
                                                <ResortFilters />
                                            </div>
                                            <div className='col-10' >
                                                <ResortListingLayout allHotelsListing={allPackageListing} />
                                            </div>

                                        </div>
                                        :
                                        null
                                }

                                {
                                    whichPageIs === 'jungle-safari'
                                        ?
                                        <div className="row">
                                            <div className="col-2">
                                                <JungleSafariFilters />
                                            </div>
                                            <div className='col-10' >
                                                <JungleSafariListingLayout allPackageListing={allPackageListing}
                                                    offer={false} offerId={false} offerDiscountPrice={false} setPage={setPage} page={page} />
                                            </div>
                                        </div>
                                        :
                                        null
                                }


                                {
                                    whichPageIs === 'package'
                                        ?
                                        <div className="row">
                                            <div className="col-2">
                                                <PackagesFilters />
                                            </div>
                                            <div className='col-10' >
                                                <PackagesListingLayout allPackageListing={allPackageListing} offer={false}
                                                    offerId={false}
                                                    offerDiscountPrice={false}
                                                    setPage={setPage}
                                                    page={page}
                                                />
                                            </div>
                                        </div>
                                        :
                                        null
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SegmentDetailsPage
