import {useEffect, useState} from 'react'
import SideBar from 'Agent/components/SideBar'
import AllBookings from 'Agent/components/AllBookings'
import { getMyHotelBookingApi } from 'Agent/apis/mainApis'

function MyBookings() {

  const [page, setPage] = useState(1)
  const [allHotelBookingData, setAllHotelBookingData] = useState([])

  useEffect(()=>{
    getData()
  },[])

  const getData = async()=>{
    let user_local_token = localStorage.getItem("maldive_auth_token")
    getMyHotelBookingApi(user_local_token, page, (data)=>{
      setAllHotelBookingData(data?.data)
    })
  }

  console.log(allHotelBookingData,'allHotelBookingData')


  return (
    <div style={{background:"#f0f0f0"}} >
      <div className="container-fluid">
         <div className="row">
             <div className="col-2">
                 <SideBar />
             </div>
             <div className="col-10">
                 <AllBookings allHotelBookingData={allHotelBookingData} />
             </div>
         </div>
      </div>
    </div>
  )
}

export default MyBookings
