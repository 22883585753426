
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function ResourceAndPolicyModal({ showResourcePolicy, setShowResourcePolicy }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showResourcePolicy}
                onHide={() => {
                    setShowResourcePolicy(false);
                }}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h6>At Nalafalhu Holidays, our extensive resources ensure an exceptional travel experience. Our dedicated Guest Relations Officers routinely contact guests, offering personalized information, and providing meet-and-greet services upon arrival, along with assistance at departure. We cater to a wide range of needs, offering child support, honeymoon packages, and services for special needs, elderly, and valued guests. Additionally, we assist with ticket bookings to further simplify your travel planning. Registered with the Ministry of Economic Development and the Ministry of Tourism, and as members of MATATO and MMPRC, we follow Maldivian laws and international best practices, ensuring your safety and satisfaction.  </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowResourcePolicy(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ResourceAndPolicyModal
