import {useState} from 'react'
import 'Customer/Styles/MainStyles/footer.css'
import { FaFacebookF, FaXTwitter   } from "react-icons/fa6";
import { FaInstagram, FaLinkedinIn  } from "react-icons/fa";

import LuxuryHotelModal from 'Customer/Modals/LuxuryHotelModal';
import CovidUpdateModal from 'Customer/Modals/CovidUpdateModal';
import MaldiveCultureModal from 'Customer/Modals/MaldiveCultureModal';

import TravelGuidModal from 'Customer/Modals/TravelGuidModal';
import MaldiveVisaModal from 'Customer/Modals/MaldiveVisaModal';

import PartnerModal from 'Customer/Modals/PartnerModal';
import ResourceAndPolicyModal from 'Customer/Modals/ResourceAndPolicyModal';

import ReturnPolicyModal from 'Customer/Modals/ReturnPolicyModal';
import PrivacyPolicyModal from 'Customer/Modals/PrivacyPolicyModal';

import AboutUsModal from 'Customer/Modals/AboutUsModal';


function Footer() {
  const [ showLuxuryHotel, setShowLuxuryHotel] = useState(false)
  const [ showCovidUpdate, setShowCovidUpdate] = useState(false)
  const [ showMaldiveCulture, setShowMaldiveCulture] = useState(false)

  const [showTravelGuid, setShowTravelGuid] = useState(false)
  const [showMaldiveVisa, setShowMaldiveVisa] = useState(false)

  const [showPartnerModal, setShowPartnerModal] = useState(false)
  const [showResourcePolicy, setShowResourcePolicy] = useState(false)
  const [showReturnPolicyModal, setShowReturnPolicyModal] = useState(false)
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false)
  
  const [showAboutUsModal, setShowAboutUsModal] = useState(false)
  return (
    <div>
      <LuxuryHotelModal showLuxuryHotel={showLuxuryHotel} setShowLuxuryHotel={setShowLuxuryHotel} />
      <CovidUpdateModal showCovidUpdate={showCovidUpdate} setShowCovidUpdate={setShowCovidUpdate} />
      <MaldiveCultureModal showMaldiveCulture={showMaldiveCulture} setShowMaldiveCulture={setShowMaldiveCulture} />
      <TravelGuidModal showTravelGuid={showTravelGuid} setShowTravelGuid={setShowTravelGuid} />
      <MaldiveVisaModal showMaldiveVisa={showMaldiveVisa} setShowMaldiveVisa={setShowMaldiveVisa} />
      <PartnerModal showPartnerModal={showPartnerModal} setShowPartnerModal={setShowPartnerModal} />
      <ResourceAndPolicyModal showResourcePolicy={showResourcePolicy} setShowResourcePolicy={setShowResourcePolicy} />


      <PrivacyPolicyModal showPrivacyPolicyModal={showPrivacyPolicyModal} setShowPrivacyPolicyModal={setShowPrivacyPolicyModal} />
      <ReturnPolicyModal showReturnPolicyModal={showReturnPolicyModal} setShowReturnPolicyModal={setShowReturnPolicyModal} />

      <AboutUsModal showAboutUsModal={showAboutUsModal} setShowAboutUsModal={setShowAboutUsModal} />

      <section className="footer_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="quick_links">
                <div className="footer_sec_heading">
                  <h4>About Maldives Holidays</h4>
                </div>
                <ul className="p-0" >
                  <li onClick={()=>{setShowAboutUsModal(true)}} >About us</li>
                  <li onClick={()=>{setShowPrivacyPolicyModal(true)}} >Privacy & policy</li>
                  <li onClick={()=>{setShowReturnPolicyModal(true)}} >Return & Refund</li>
                  <li onClick={()=>{setShowPartnerModal(true)}} >Resources and Policies</li>
                  <li onClick={()=>{setShowResourcePolicy(true)}} >Partners</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <div className="quick_links">
                <div className="footer_sec_heading">
                  <h4>Explore</h4>
                </div>
                <ul className="p-0" >
                  <li onClick={()=>{setShowLuxuryHotel(true)}} >Luxury Resorts</li>
                  <li onClick={()=>{setShowCovidUpdate(true)}} >Covid-19 Updates</li>
                  <li onClick={()=>{setShowMaldiveCulture(true)}} >Maldives Culture</li>
                  <li onClick={()=>{setShowTravelGuid(true)}} >Travel Guide</li>
                  <li onClick={()=>{setShowMaldiveVisa(true)}} >Maldives Visa</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <div className="quick_links">
                <div className="footer_sec_heading">
                  <h4>Do Business With Us</h4>
                </div>
                <ul className="p-0" >
                  <li>Accommodation Owners</li>
                  <li>Business Advantage</li>
                  <li>Advertise with Us</li>
                  <li>Affiliates & Partners</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer_social_links_wrappers">
                <div className="footer_sec_heading">
                  <h4>Follow Us</h4>
                </div>

                <div className="footer_social_links">
                  <ul className="p-0" >
                    <li className="social_link"><a href="#"><FaFacebookF/></a></li>
                    <li className="social_link"><a href="#"><FaInstagram /></a></li>
                    <li className="social_link"><a href="#"><FaXTwitter /></a></li>
                    <li className="social_link"><a href="#"><FaLinkedinIn /></a></li>
                  </ul>
                </div>

                <div className="footer_logo">
                  <div className="footer_logo_img">
                    <img src="/img/footer-logo1.png" alt="" />
                  </div>
                  <div className="footer_logo_img">
                    <img src="/img/footer-logo2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default Footer
