
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function PrivacyPolicyModal({ showPrivacyPolicyModal, setShowPrivacyPolicyModal }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showPrivacyPolicyModal}
                onHide={() => {
                    setShowPrivacyPolicyModal(false);
                }}
            >
                <Modal.Header closeButton>
                    Privacy Policy
                </Modal.Header>
                <Modal.Body>
                    <h6 className="mt-2" >At Nalafalhu Holidays, we are dedicated to safeguarding the privacy of our guests and ensuring clear communication regarding our policies. Our privacy and policies are provided with each booking confirmation, tailored to the specific tourist facility, as these may differ between arrangements. </h6>
                    <h6 className="mt-2" >Our policy framework respects the mutually agreed terms between all parties, with particular emphasis on strict conditions for cancellations and no-shows after booking confirmation. We urge our guests to thoroughly read and understand these terms before reconfirming any arrangement.</h6>
                    <h6 className="mt-2" >Policies are enforced in accordance with the requirements of the respective resorts or hotels. Nonetheless, we commit to minimizing any possible inconvenience or losses</h6>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowPrivacyPolicyModal(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PrivacyPolicyModal
