import { useState, useLayoutEffect } from 'react'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import ResortPhotoGallery from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortPhotoGallery'

import ResortsTabs from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortsTabs'
import ResortOverview from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortOverview'
import ResortAmenities from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortAmenities'
import ResortLocation from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortLocation'
import ResortGuestReview from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortGuestReview'
import ResortCancellationPolicy from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortCancellationPolicy'
import ResortPolicy from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortPolicy'
import ResortSimiliar from 'Customer/Layouts/ResortsDetailsPageLayouts/ResortSimiliar'
import { getSingleHotelApi, getAllOffersApi } from 'Customer/Apis/MainApis/mainApis'

import AllRoomsList from 'Customer/Layouts/ResortsDetailsPageLayouts/AllRoomsList'


function ResortsDetailsPage() {
  const [selectedTab, setSelectedTab] = useState('1')
  const [singleHotelData, setSingleHotelData] = useState(false)
  const [singleHotelRatings, setSingleHotelRatings] = useState([])
  const [totalRatings, setTotalRatings] = useState()
  const [allOfferLists, setAllOffersList] = useState([])
  const [allRoomsLists, setAllRoomssList] = useState([])
  const [vendorId, setVendorId] = useState('')
  const [bookingSuccessShow, setBookingSuccessShow] = useState(false)
  
  let hotelID = window.location.pathname.split("/")[3];
  useLayoutEffect(() => {
    getSingleHotel()
    getAllOffers()
  }, [hotelID, bookingSuccessShow])

  const getSingleHotel = async () => {
    getSingleHotelApi(hotelID, (data) => {
      setVendorId(data?.data?.vendorId)
      setSingleHotelData(data?.data)
      setSingleHotelRatings(data?.ratings)
      setAllRoomssList(data?.rooms)
      setTotalRatings(data?.avgRating)
    })
  }

  const getAllOffers = async () => {
    getAllOffersApi(1, "Hotel", (data) => {
      setAllOffersList(data?.data)
    })
  }
  
  return (
    <div>
      <HomeBanner />
      {
        singleHotelData === false
          ?
          null
          :
          <ResortPhotoGallery singleHotelData={singleHotelData}  allOfferLists={allOfferLists}/>
      } 
      <AllRoomsList allRoomsLists={allRoomsLists} vendorId={vendorId} hotelID={hotelID} bookingSuccessShow={bookingSuccessShow} setBookingSuccessShow={setBookingSuccessShow} />

      <ResortsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {
        selectedTab === '1'
          ?
          <ResortOverview hotelOverview={singleHotelData?.overview} />
          : null
      }
      {
        selectedTab === '2'
          ?
          <ResortAmenities singleHotelData={singleHotelData} />
          : null
      }
      {
        selectedTab === '3'
          ?
          <ResortLocation singleHotelData={singleHotelData} />
          : null
      }
      {
        selectedTab === '4'
          ?
          <ResortGuestReview singleHotelData={singleHotelData} singleHotelRatings={singleHotelRatings} totalRatings={totalRatings} />
          : null
      }
      {
        selectedTab === '5'
          ?
          <ResortCancellationPolicy cancelPolicy={singleHotelData?.cancelPolicy} />
          : null
      }
      {
        selectedTab === '6'
          ?
          <ResortPolicy resortPolicy={singleHotelData?.resortPolicy} />
          : null
      }
      {
        selectedTab === '7'
          ?
          <ResortSimiliar />
          : null
      }






    </div>
  )
}

export default ResortsDetailsPage
