
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function PartnerModal({ showPartnerModal, setShowPartnerModal }) {
    const navigation = useNavigate()
    return (
        <div>
            <Modal
                centered
                show={showPartnerModal}
                onHide={() => {
                    setShowPartnerModal(false);
                }}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h6>At Nalafalhu Holidays, we have established strong partnerships with leading luxury resorts, distinguished overseas tour operators, renowned airline operators, and specialized diving safari providers. Our in-house team is composed of dedicated, well-qualified professionals with multicultural expertise, speaking various languages including Russian and Chinese. These partnerships and our diverse team enable us to offer tailored, high-quality experiences, meeting the diverse needs of travelers from around the globe. Trust us to make your Maldivian adventure seamless and memorable. </h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowPartnerModal(false);
                        }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PartnerModal
